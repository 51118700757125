import React from 'react';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import BookingIcon from '@mui/icons-material/Work';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { Link } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import Navbar from '../Components/common/Navbar';
import { useLocation, useNavigate } from 'react-router-dom';
import { logout } from "../slices/UserSlices";
import { useDispatch } from 'react-redux';
import toast from 'react-hot-toast';
import { Helmet } from 'react-helmet';
import SEOHelmet from '../Components/common/SeoHelmet';

const UserDashboard = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const currentPath = location.pathname.split('/')[2]?.toLowerCase();
    const user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem('user')) : null;

    const menuItems = [
        { name: 'Profile', path: 'user/Profile', icon: <PersonOutlineIcon /> },
        { name: 'Booking', path: 'user/Booking/all-listed-vehicle-types', icon: <BookingIcon /> },
        // { name: 'Password', path: 'user/Password-Set', icon: <LockOpenIcon /> },
    ];

    const handleLogout = () => {
        dispatch(logout());
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        toast.success("Logged Out");
        navigate("/"); 
    };

    return (
        <section className="w-full ">
              <SEOHelmet title="User Profile | TSJ Car Rentals"/>

              
            <Navbar />
            <div className="flex  lg:flex-row lg:w-[90vw] md:w-[100vw] mx-auto mt-5">
                {/* Sidebar */}
                <aside className="xsm:[150px] md:w-[200px] lg:w-[25%] bg-white  h-[90vh] sm:h-[70vh] md:h-[70vh] shadow-lg p-5 rounded-md overflow-x-auto">
                    <div className="text-center mb-8">
                        {/* Profile image */}
                        <div className="w-20 h-20 mx-auto mb-4 rounded-full bg-gradient-to-tr from-green-400 to-blue-500 text-white flex items-center justify-center text-3xl font-bold">
                            {user?.name?.slice(0,1)?.toUpperCase()}
                        </div>
                        <h2 className="text-xl font-semibold">{user?.name}</h2>
                        <p className="text-[0.6rem] font-lato text-[#4A4A4A]">PERSONAL PROFILE</p>
                    </div>

                    {/* Navigation */}
                    <nav>
                        <ul className="space-y-4">
                            {menuItems.map((item, index) => (
                                <li key={index}>
                                    <Link
                                        to={`/${item.path}`}
                                        className={`flex font-lato items-center space-x-3 cursor-pointer p-2 rounded-md text-[0.8rem] ${currentPath === item.path.split('/')[1]?.toLowerCase()
                                            ? "text-[#008CFF] bg-[#008CFF1A]"
                                            : "text-[#4A4A4A]"
                                            } font-semibold`}
                                    >
                                        <span>{item.icon}</span>
                                        <span>{item.name}</span>
                                    </Link>
                                </li>
                            ))}
                            <li className="flex font-lato cursor-pointer items-center space-x-3 p-2 rounded-md text-[0.8rem]" onClick={handleLogout}>
                                <span>{<ExitToAppIcon />}</span>
                                <span>Logout</span>
                            </li>
                        </ul>
                    </nav>
                </aside>

                {/* Main content */}
                <main className="flex-1 px-5 md:w-[75%] max-w-full overflow-x-auto">
                    <Outlet />
                </main>
            </div>
        </section>
    );
};

export default UserDashboard;
