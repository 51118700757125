import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAllGalleryImages } from '../../../Services/adminAPI';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Paper,
    Typography,
    Box, Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import { formatDate } from '../../../utils/FormateValue';

const AllGalleryImagesList = () => {
    const location = useLocation();
    const loginType = location.pathname.split("/")[1]
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [gallery, setGallery] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();


    useEffect(() => {
        const fetchGallery = async () => {
            try {
                const response = await getAllGalleryImages()
                setGallery(response?.data?.items);
            } catch (error) {
                console.error("Error fetching bookings:", error);
            }
        };

        fetchGallery();
    }, []);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const handleRowClick = (item) => {
        console.log("item",item)
        setSelectedImage(item);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedImage(null);
    };

    return (
        <>
            <Paper sx={{ width: '100%', overflow: 'hidden', marginTop: 4, overflowY: "scroll" }}>
                <Box p={2} sx={{ textAlign: "start" }}>
                    <Typography variant="h5" sx={{ fontWeight: "600", float: "left" }}>All Uploaded Gallery Images</Typography>
                </Box>
                <TableContainer component={Paper}
                    sx={{
                        height: '400px',
                        overflowY: 'scroll',
                    }}>
                    <Table stickyHeader aria-label="vehicle table">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ backgroundColor: "primary.main", color: "white" }}>Title</TableCell>
                                <TableCell sx={{ backgroundColor: "primary.main", color: "white" }}>Image</TableCell>
                                <TableCell sx={{ backgroundColor: "primary.main", color: "white" }}>Uploaded On</TableCell>
                                <TableCell sx={{ backgroundColor: "primary.main", color: "white" }}>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {gallery?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item) => (
                                <TableRow
                                    hover
                                    key={item._id}
                                    onClick={() => handleRowClick(item)}
                                    sx={{ cursor: 'pointer' }}
                                >
                                    <TableCell sx={{ color: "gray" }}>{item.title}</TableCell>
                                    <TableCell sx={{ color: "gray" }}>
                                        <img src={item.mediaUrl} alt={item.title} width={50} height={50} />
                                    </TableCell>
                                    <TableCell sx={{ color: "gray" }}>{formatDate(item.createdAt)}</TableCell>
                                    <TableCell sx={{ color: "gray" }}>view</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>


                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={gallery?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>


            <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
                <DialogContent>
                    {selectedImage && (
                        <>
                            <Typography variant="h6" gutterBottom>
                                {selectedImage.title}
                            </Typography>
                            <Box textAlign="center">
                                <img
                                    src={selectedImage.mediaUrl}
                                    alt={selectedImage.title}
                                    style={{ maxWidth: "100%", height: "auto" }}
                                />
                            </Box>
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>

    );
};

export default AllGalleryImagesList; 
