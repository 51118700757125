import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "flag-icon-css/css/flag-icons.min.css";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast, Toaster } from "react-hot-toast";
import axios from "axios";
import { BASE_API } from "../Services/apis";
import { setSignupData } from "../slices/authSlices";
import Signup from "../Assests/Auth/Login.png";
import logo from "../Assests/Home/image 14.png";
import LoginOtpForm from "./LoginOtpForm";

import SEOHelmet from "../Components/common/SeoHelmet";

const UserLogin = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const signupData = useSelector((state) => state?.signup?.signupData);
    const [isOtpSent, setIsOtpSent] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loginType, setLoginType] = useState("mobile");
    const [mobile, setMobile] = useState("");
    const [email, setEmail] = useState("");
    const [loginData, setLoginData] = useState(null);
    const [orderId, setOrderId] = useState(null);
    const isSubmitDisabled = loginType === "mobile" ? !mobile : !email;


    const handleLoginType = (type) => {
        setLoginType(type);
        setMobile("");
        setEmail("");
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        const payload = loginType === "mobile"
            ? { mobile: mobile.replace(/\D/g, "") }
            : { email };
        const endpoint = `${BASE_API}${loginType === "mobile" ? "/user/login" : "/user/login-with-email"}`;

        try {
            const response = await axios.post(endpoint, payload);
            if (response.status === 200 || response.status === 201) {
                const orderId = response.data.data.orderId;
                localStorage.setItem("orderId", JSON.stringify(orderId));
                dispatch(setSignupData({ ...signupData, orderId }));
                toast.success("OTP successfully sent!");
                setLoginData(loginType === "mobile" ? mobile : email);
                setOrderId(orderId);
                setIsOtpSent(true);
            }
        } catch (error) {
            if (error.response?.status === 404) {
                navigate("/signup");
            } else {
                toast.error(error.response?.data?.message || "Something went wrong");
            }
        } finally {
            setLoading(false);
        }
    };

    const renderInputField = () => {
        if (loginType === "mobile") {
            return (
                <div>
                    <label className="block text-gray-700 text-sm font-bold mb-2">Mobile</label>
                    <PhoneInput
                        country={"in"}
                        value={mobile}
                        onChange={setMobile}
                        inputStyle={{
                            width: "100%",
                            border: "1px solid #D4D7E3",
                            backgroundColor: "#F7FBFF",
                            borderRadius: "0.375rem",
                            padding: "0.5rem 1rem",
                            fontSize: "0.9rem",
                        }}
                        buttonStyle={{
                            border: "1px solid #D4D7E3",
                            backgroundColor: "#F7FBFF",
                        }}
                        dropdownStyle={{ backgroundColor: "#F7FBFF" }}
                    />
                </div>
            );
        }
        return (
            <div>
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                    Email
                </label>
                <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="Enter your email"
                />
            </div>
        );
    };

    const renderForm = () => (
        <form className="space-y-4" onSubmit={handleSubmit}>
            <div className="flex space-x-4">
                <button
                    type="button"
                    onClick={() => handleLoginType("mobile")}
                    className={`w-1/2 py-2 rounded-md ${loginType === "mobile" ? "bg-blue-500 text-white" : "bg-gray-200 text-gray-700"
                        }`}
                >
                    Mobile Login
                </button>
                <button
                    type="button"
                    onClick={() => handleLoginType("email")}
                    className={`w-1/2 py-2 rounded-md ${loginType === "email" ? "bg-blue-500 text-white" : "bg-gray-200 text-gray-700"
                        }`}
                >
                    Email Login
                </button>
            </div>
            {renderInputField()}
            <button
                type="submit"
                className={`w-full h-12 bg-[#162D3A] text-white rounded-md hover:bg-gray-800 ${isSubmitDisabled ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                disabled={isSubmitDisabled}
            >
                {loading ? "Loading..." : `Login With ${loginType === "mobile" ? "Mobile" : "Email"}`}
            </button>
        </form>
    );

    return (
        <>

            <SEOHelmet title="Login | TSJ Car Rentals" />

            <Toaster />
            {!isOtpSent &&
                <div className="flex md:flex-row xsm:flex-col-reverse justify-center items-center h-screen">
                    <div className="hidden md:grid grid-cols-12 rounded-lg shadow-lg w-[100vw] h-[100vh] p-4">
                        <div className="col-span-6 flex flex-col justify-center items-center">
                            <div className="w-full flex justify-center mb-6">
                                <Link to="/">
                                    <img src={logo} className="w-[50px] h-[50px]" alt="Logo"/>
                                </Link>
                            </div>
                            <div className="px-8 w-[65%] mx-auto flex flex-col space-y-10">
                                <h2 className="text-[36px] text-[#0C1421] font-bold">Welcome Back 👋</h2>
                                <p className="text-[1rem] text-[#313957] mb-6">
                                    Today is a new day. It’s your day. You shape it. Sign in to start managing your projects.
                                </p>
                                {renderForm()}
                                <p className="text-center mt-4 text-gray-700">
                                    Don't have an account? <Link to="/signup" className="text-blue-600">Sign Up</Link>
                                </p>
                                <p className="text-center my-20 text-[#959CB6] text-[0.8rem]">© 2023 ALL RIGHTS RESERVED BY TSJ</p>
                            </div>
                        </div>
                        <div className="col-span-6 h-[98vh]">
                            <img src={Signup} alt="Signup" className="w-full h-full object-cover rounded-lg" />
                        </div>
                    </div>
                    <div
                        style={{ backgroundImage: `url(${Signup})` }}
                        className="md:hidden relative w-[100vw] flex items-center justify-center h-full bg-cover bg-center rounded-[0px]"
                    >
                        <div className="absolute top-20 left-1/2 transform -translate-x-1/2">
                            <Link to="/">
                                <img src={logo} className="w-[50px] h-[50px]" alt="Logo" />
                            </Link>
                        </div>
                        <div className="px-6 w-[90%] rounded-lg flex flex-col space-y-10 backdrop-blur-md">
                            <h2 className="text-[36px] text-white font-bold">Welcome Back 👋</h2>
                            <p className="text-[1rem] text-white mb-6">
                                Today is a new day. It’s your day. You shape it. Sign in to start managing your projects.
                            </p>
                            {renderForm()}
                            <p className="text-center mt-4 text-white">
                                Don't have an account? <Link to="/signup" className="text-blue-600">Sign Up</Link>
                            </p>

                            <p className="text-center my-20 text-[#fff] text-[0.8rem]">© 2023 ALL RIGHTS RESERVED BY TSJ</p>
                        </div>
                    </div>
                </div>
            }

            {isOtpSent && <LoginOtpForm loginType={loginType} loginData={loginData} orderId={orderId} />}
        </>
    );
};

export default UserLogin;
