import React, { useState, useEffect } from "react";
import EditProfileModal from "./EditProfileModel";

import SEOHelmet from "../../common/SeoHelmet";

const Profile = () => {


    const [formData, setFormData] = useState({
        Name: '',
        Birthday: '',
        Gender: '',
        MaritalStatus: '',
        Address: '',
        Pincode: '',
        State: '',
    });
    useEffect(() => {
        const userData = JSON.parse(localStorage.getItem("user"));

        if (userData) {
            setFormData({
                Name: userData.name || '',
                Email: userData.email || '',
                Mobile: userData.mobile || '',
                Address: userData.address || '',
                Nationality: userData?.nationality || ''
            });
        }
    }, []);

    const [isModalOpen, setModalOpen] = useState(false);

    const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);

    return (
        <>
                  <SEOHelmet title="User Profile | TSJ Car Rentals"/>

            <div className="bg-white shadow-lg xsm:w-[600px]  md:w-full rounded-lg p-6 border-[#cacaca] border-2">
                <div className="flex justify-between items-center mb-6">
                    <h2 className="text-lg font-semibold">Profile</h2>
                    <button className="text-blue-600">
                        <span className="material-icons" onClick={openModal}>Edit Profile</span>
                    </button>
                </div>
                <div>
                    <div className="grid grid-cols-2 gap-4 mb-4 font-lato text-[0.9rem]">
                        {Object.keys(formData).map((value, index) => (
                            <React.Fragment key={index}>
                                <div className="col-span-2 border-b border-gray-300"></div>
                                <div className="flex text-start">
                                    <h4 className="font-bold w-[25rem] text-gray-700">{value}</h4>
                                    {formData[value] ? (
                                        <p className="text-black w-[25rem]">{formData[value]}</p>
                                    ) : (
                                        <p className="text-[#008CFF] font-semibold  w-[25rem]" onClick={openModal}>+ Add</p>
                                    )}

                                </div>
                            </React.Fragment>
                        ))}
                    </div>
                </div>
                <EditProfileModal isOpen={isModalOpen} onClose={closeModal} setFormData={setFormData} formData={formData} />
            </div>
        </>
    );
};

export default Profile;
