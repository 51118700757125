import React from "react";
import { motion } from "framer-motion";
import Navbar from "../Components/common/Navbar";
import SearchBar from "../Components/Core/TourAndTravels/Searchbar";
import MainSection from "../Components/Core/TourAndTravels/MainSection";
import { useLocation } from "react-router-dom";

import SEOHelmet from "../Components/common/SeoHelmet";
const TourandTravel = () => {
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search);
    const to = searchParams.get("dropLocation") || "";

    return (
        <section className="w-[100vw] ">
            <SEOHelmet title="Tour&Travel Packages | TSJ Car Rentals"/>
             
            <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }} 
                transition={{ duration: 0.5 }} 
            >
                <Navbar />
            </motion.div>
            <motion.div>
                <SearchBar />
            </motion.div>
            <motion.div
                className="listingBannerimg bg-cover bg-center bg-no-repeat relative z-10"
                style={{
                    backgroundImage: "url('https://hldak.mmtcdn.com/prod-s3-hld-hpcmsadmin/holidays/images/listingHeaderImages/1440x320-Goa-10-06-2021.jpg')",
                    backgroundColor: 'rgb(4, 20, 34)',
                    height: "15rem",
                }}
                initial={{ opacity: 0, scale: 0.9 }} 
                animate={{ opacity: 1, scale: 1 }} 
                transition={{ duration: 0.5, delay: 0.4 }}
            >
                {/* Overlay effect with gradient shadow */}
                <div className="absolute inset-0 w-full h-full bg-gradient-to-r from-black to-transparent opacity-50 shadow-lg"></div>

                <motion.div
                    className="absolute z-30 top-[10%] md:top-[15%] left-[5%] md:left-[10%] flex justify-center items-center w-fit px-4"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }} 
                    transition={{ duration: 0.5, delay: 0.6 }}
                >
                    <div className=" text-center md:text-left w-full">
                        <div className="">
                            {/* Responsive Heading */}
                            <h1 className="text-white text-2xl w-fit sm:text-3xl md:text-4xl font-bold leading-tight">
                                {to} Packages
                            </h1>
                            {/* <p className="text-white text-sm w-fit sm:text-base md:text-lg mt-2">
                                Experience beaches and sunset
                            </p> */}
                        </div>
                    </div>
                </motion.div>
            </motion.div>

            <MainSection />
        </section>
    )
}

export default TourandTravel;