import React from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Paper } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/ExitToApp';
import { useDispatch } from 'react-redux';
import { logout } from '../../../../slices/UserSlices'; 
import { useNavigate } from 'react-router-dom';
import  toast  from 'react-hot-toast';



const Sidebar = ({menuItems}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const handleLogout = () => {
        dispatch(logout());
        toast.success('Logged out successfully!');
        navigate('/');
    };

    return (
        <Paper sx={{ height: "100vh" }}>
            <List>
                {menuItems.map((item, index) => (
                    <ListItem
                        button
                        key={index}
                        component={Link}
                        to={item.path}
                        sx={{
                            backgroundColor: location.pathname === `/${item.path}` ? 'gray-300' : 'transparent', // Change background color if active
                        }}
                    >
                        <ListItemIcon>{item.icon}</ListItemIcon>
                        <ListItemText primary={item.name} />
                    </ListItem>
                ))}
                <ListItem sx={{cursor:"pointer"}} button onClick={handleLogout} >
                    <ListItemIcon><LogoutIcon /></ListItemIcon>
                    <ListItemText primary="Logout" />
                </ListItem>
            </List>
        </Paper>
    );
};

export default Sidebar;
