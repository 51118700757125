import React, { useEffect, useState } from 'react';
import { getAllOnboardVehicleList, OnboardNewRentalVehicle, updateRentalVehicleStatus } from '../../Services/AddVehicleDetails';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    TablePagination, Paper, Typography, Button, Box, IconButton, Switch
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import RentalVehicleModal from '../Models/VehicleRentalModal';
import EditRentalVehicleModal from '../Models/EditRentalVehicleModal';
import SEOHelmet from '../../Components/common/SeoHelmet';

const OnboardedVehicleList = () => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [vehicles, setVehicles] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [selectedVehicle, setSelectedVehicle] = useState(null);

    const fetchVehicles = async () => {
        try {
            const response = await getAllOnboardVehicleList();
            setVehicles(response?.data?.data);
        } catch (error) {
            console.error("Error fetching vehicle list:", error);
        }
    };
    useEffect(() => {
        fetchVehicles();
    }, []);

    const handleAddVehicle = async (vehicleData) => {
        try {
            const response = await OnboardNewRentalVehicle(vehicleData);
            if (response.status === 200) {
                fetchVehicles();
            }
        } catch (error) {
            console.error("Error adding vehicle:", error);
        }
    };


    const handleEditVehicle = (vehicle) => {
        setSelectedVehicle(vehicle);
        setEditModalOpen(true);
    };

    const handleToggleStatus = async (vehicle) => {
        try {
            const updatedStatus = !vehicle.available;
            const response = await updateRentalVehicleStatus(vehicle._id, updatedStatus);
            if (response.status === 200) {
                fetchVehicles()
            }
        } catch (error) {
            console.error('Error updating vehicle status:', error);
        }
    };
    return (
        <Paper sx={{ width: '100%', maxHeight: "72vh" }}>
            <SEOHelmet title="Onboarded Rental Vehicle | TSJ Car Rentals " />

            <Box
                p={2}
                sx={{
                    textAlign: "start",
                    display: "flex",
                    justifyContent: "space-between",
                    color: "white",
                }}
            >
                <Typography
                    variant="h5"
                    sx={{
                        fontWeight: "600",
                        color: "black",
                        float: "left"
                    }}
                >
                    Added Rental Vehicle
                </Typography>
                <Button
                    sx={{
                        backgroundColor: "primary.main",
                        color: "white",
                        "&:hover": {
                            backgroundColor: "primary.dark", // Add hover effect
                        },
                    }}
                    onClick={() => setModalOpen(true)}
                >
                    Add New Rental Vehicle
                </Button>
            </Box>

            <TableContainer component={Paper} sx={{
                // height: '400px',
                overflowY: 'scroll',
            }}>
                <Table stickyHeader aria-label="vehicle table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ backgroundColor: "primary.main", color: "white" }}>Vehicle Type</TableCell>
                            <TableCell sx={{ backgroundColor: "primary.main", color: "white" }}>Model</TableCell>
                            <TableCell sx={{ backgroundColor: "primary.main", color: "white" }}>Fuel Type</TableCell>
                            <TableCell sx={{ backgroundColor: "primary.main", color: "white" }}>Transmission</TableCell>
                            <TableCell sx={{ backgroundColor: "primary.main", color: "white" }}>Rate/Day (₹)</TableCell>
                            <TableCell sx={{ backgroundColor: "primary.main", color: "white" }}>Seats</TableCell>
                            <TableCell sx={{ backgroundColor: "primary.main", color: "white" }}>Contact</TableCell>
                            <TableCell sx={{ backgroundColor: "primary.main", color: "white" }}>Available Location</TableCell>

                            <TableCell sx={{ backgroundColor: "primary.main", color: "white" }}>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {vehicles?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((vehicle) => (
                            <TableRow key={vehicle._id} hover>
                                <TableCell sx={{ color: "gray" }}>{vehicle.vehicleType}</TableCell>
                                <TableCell sx={{ color: "gray" }}>{vehicle.vehicleModel}</TableCell>
                                <TableCell sx={{ color: "gray" }}>{vehicle.fuelType}</TableCell>
                                <TableCell sx={{ color: "gray" }}>{vehicle.transmission}</TableCell>
                                <TableCell sx={{ color: "gray" }}>{`₹${vehicle.ratePerDay}`}</TableCell>
                                <TableCell sx={{ color: "gray" }}>{vehicle.seats}</TableCell>
                                <TableCell sx={{ color: "gray" }}>{vehicle.contactNumber}</TableCell>
                                <TableCell sx={{ color: "gray" }}>{vehicle?.availableLocation}</TableCell>


                                <TableCell>
                                    <IconButton
                                        onClick={() => handleEditVehicle(vehicle)}
                                        color="primary"
                                    >
                                        <EditIcon />
                                    </IconButton>
                                    <Switch
                                        checked={vehicle.available}
                                        onChange={() => handleToggleStatus(vehicle)}
                                        color="primary"
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={vehicles.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={(e, newPage) => setPage(newPage)}
                onRowsPerPageChange={(e) => setRowsPerPage(parseInt(e.target.value, 10))}
            />
            <RentalVehicleModal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                onSubmit={handleAddVehicle}
                fetchVehicles={fetchVehicles}
            />

            <EditRentalVehicleModal
                open={editModalOpen}
                onClose={() => setEditModalOpen(false)}
                onSubmit={handleAddVehicle}
                fetchVehicles={fetchVehicles}
                selectedVehicle={selectedVehicle}
            />
        </Paper>
    );
};

export default OnboardedVehicleList;
