import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { vendorRequest } from '../../../Services/adminAPI';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Paper,
    Typography,
    Box, Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import { formatDate } from '../../../utils/FormateValue';

const VendorRequestList = () => {
    const location = useLocation();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [vendorList, setVendorList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedVendor, setSelectedVendor] = useState(null);
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchVendorRequests = async () => {
            try {
                const response = await vendorRequest();
                setVendorList(response?.data?.data);
            } catch (error) {
                console.error("Error fetching vendor requests:", error);
            }
        };
        fetchVendorRequests();
    }, []);

    const formatAddress = (address) => {
        if (!address) return "N/A";
        const { street, city, postalCode, country } = address;
        return `${street}, ${city}, ${postalCode}, ${country}`;
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleRowClick = (item) => {
        setSelectedVendor(item);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedVendor(null);
    };

    const renderImage = (imageUrl) => {
        return imageUrl ? (
            <Button variant="text" color="primary" onClick={() => window.open(imageUrl, '_blank')}>
                View Image
            </Button>
        ) : (
            <Typography variant="body2" color="textSecondary">No Image Available</Typography>
        );
    };

    return (
        <>
            <Paper sx={{ width: '100%', overflow: 'hidden', marginTop: 4 }}>
                <Box p={2} sx={{ textAlign: "start" }}>
                    <Typography variant="h5" sx={{ fontWeight: "600" }}>All Vendor Requests</Typography>
                </Box>
                <TableContainer component={Paper} sx={{ maxHeight: '400px', overflowY: 'scroll' }}>
                    <Table stickyHeader aria-label="vendor table">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ backgroundColor: "primary.main", color: "white" }}>Vendor Name</TableCell>
                                <TableCell sx={{ backgroundColor: "primary.main", color: "white" }}>Mobile</TableCell>
                                <TableCell sx={{ backgroundColor: "primary.main", color: "white" }}>Vehicle Type</TableCell>
                                <TableCell sx={{ backgroundColor: "primary.main", color: "white" }}>Vehicle Model</TableCell>
                                <TableCell sx={{ backgroundColor: "primary.main", color: "white" }}>Status</TableCell>
                                <TableCell sx={{ backgroundColor: "primary.main", color: "white" }}>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {vendorList?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item) => (
                                <TableRow
                                    hover
                                    key={item._id}
                                    onClick={() => handleRowClick(item)}
                                    sx={{ cursor: 'pointer' }}
                                >
                                    <TableCell>{item?.vendorDetails?.name}</TableCell>
                                    <TableCell>{item?.vendorDetails?.mobile}</TableCell>
                                    <TableCell>{item?.vehicleDetails?.vehicleType || "N/A"}</TableCell>
                                    <TableCell>{item?.vehicleDetails?.vehicleModel || "N/A"}</TableCell>
                                    <TableCell>{item.status}</TableCell>
                                    <TableCell>view</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={vendorList?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>

            <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
                <DialogTitle>Vendor Details</DialogTitle>
                <DialogContent>
                    {selectedVendor && (
                        <>
                            <Typography variant="h6">Vendor Information</Typography>
                            <Typography><strong>Name:</strong> {selectedVendor.vendorDetails.name}</Typography>
                            <Typography><strong>Email:</strong> {selectedVendor.vendorDetails.email}</Typography>
                            <Typography><strong>Mobile:</strong> {selectedVendor.vendorDetails.mobile}</Typography>
                            <Typography><strong>Company:</strong> {selectedVendor.vendorDetails.companyName}</Typography>
                            <Typography><strong>Address:</strong> {formatAddress(selectedVendor.vendorDetails.address)}</Typography>
                            <Typography><strong>GST Number:</strong> {selectedVendor.vendorDetails.gstNumber}</Typography>
                            <Typography><strong>PAN Number:</strong> {selectedVendor.vendorDetails.panNumber}</Typography>
                            <Typography><strong>Aadhar Card Number:</strong> {selectedVendor.vendorDetails.aadharCardNumber}</Typography>
                            {renderImage(selectedVendor.vendorDetails.aadharCardFrontImg)}
                            {renderImage(selectedVendor.vendorDetails.aadharCardBackImg)}
                            {renderImage(selectedVendor.vendorDetails.dlImg)}

                            <Typography variant="h6" sx={{ marginTop: 2 }}>Vehicle Information</Typography>
                            <Typography><strong>Vehicle Model:</strong> {selectedVendor.vehicleDetails.vehicleModel}</Typography>
                            <Typography><strong>Vehicle Type:</strong> {selectedVendor.vehicleDetails.vehicleType}</Typography>
                            <Typography><strong>Registration Number:</strong> {selectedVendor.vehicleDetails.registrationNumber}</Typography>
                            <Typography><strong>Fuel Type:</strong> {selectedVendor.vehicleDetails.fuelType}</Typography>
                            <Typography><strong>Transmission:</strong> {selectedVendor.vehicleDetails.transmission}</Typography>
                            {selectedVendor.vehicleDetails.amenities.length > 0 && (
                                <Typography><strong>Amenities:</strong> {selectedVendor.vehicleDetails.amenities.join(', ')}</Typography>
                            )}
                            {renderImage(selectedVendor.vehicleDetails.pollutionCertificateImg)}
                            {renderImage(selectedVendor.vehicleDetails.odoMeterImg)}
                            {renderImage(selectedVendor.vehicleDetails.vehicleImg)}
                            {renderImage(selectedVendor.vehicleDetails.insuranceImg)}
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">Close</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default VendorRequestList;
