import React from 'react';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';

const BookingDetails = ({selectedVehicle}) => {
    return (
        <div 
        style={{boxShadow: "0px 4px 8px 0px #8A6B7614"            }}
        className="font-sans antialiased text-gray-900 text-start">
            <div className="w-[90vw] md:container mx-auto py-10 space-y-8">
                {/* Driver & Cab Details Section */}
                

                {/* Inclusions & Exclusions Section */}
                <div className="grid  grid-cols-2 gap-6 border rounded-lg  shadow ">
                    <div className="p-6 text-[0.9rem]">
                        <h3 className="font-bold text-lg mb-4">
                            <FaCheckCircle className="text-green-500 inline-block mr-2" />
                            Inclusions <span className="text-sm">(Included in the Price)</span>
                        </h3>
                        <ul className="list-disc pl-5 space-y-2 font-bold">
                            <li>Parking Charges</li>
                            <li>State Tax</li>
                            <li>Toll Charges</li>
                            <li>{selectedVehicle?.limitPer24Hrs} Kms</li>
                            <li>Driver Allowance</li>
                            <li>Only One Pickup and Drop <span className="text-sm">(Only one included)</span></li>
                        </ul>
                    </div>
                    <div className="p-6">
                        <h3 className="font-bold text-lg mb-4">
                            <FaTimesCircle className="text-red-500 inline-block mr-2" />
                            Exclusions <span className="text-sm">(Extra Charges)</span>
                        </h3>
                        <ul className="list-disc  md:pl-5 space-y-2">
                            <li className="flex justify-between">
                                <span>Fare beyond {selectedVehicle?.limitPer24Hrs} Kms</span>
                                <span className='font-bold'>₹{selectedVehicle?.extraKmRate}/Km</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BookingDetails;
