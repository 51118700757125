export const budgetRanges = [
    { id: 1, label: "₹20,000", min: 20000, max: 200000, count: 60 },
    { id: 2, label: "₹20,000 - ₹30,000", min: 20000, max: 30000, count: 52 },
    { id: 3, label: "₹30,000 - ₹45,000", min: 30000, max: 45000, count: 57 },
    { id: 4, label: "₹45,000", min: 45000, max: 200000, count: 36 },
    { id: 5, label: "₹150,000 - ₹200,000", min: 150000, max: 200000, count: 6 },
];



export const manageTypes = [
    {
        "id": 1,
        "title": "Rental Types",
        "link": "/user/booking/all-listed-vehicle-types",
    },
    {
        "id": 2,
        "title": "Tour and Travel types",
        "link": "/user/booking/all-vehicle-types",
    },
    {
        "id": 3,
        "title": "Taxi Type",
        "link": "/user/booking/all-vehicle-modal",
    },
   
]