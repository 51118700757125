import React, { useEffect, useState } from "react";
import { Container, Paper, Typography, Box, Grid2, Button, Divider, Dialog, DialogTitle, DialogContent, DialogActions,IconButton } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useLocation, useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import SEOHelmet from "../../common/SeoHelmet";

const BookingConfirmation = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [openPopup, setOpenPopup] = useState(false);
    const [contactDetails, setContactDetails] = useState("");
    const bookingData = location.state?.booking;

    useEffect(() => {
        const pickupLocation = bookingData?.pickupLocation?.toLowerCase();
        let details = "Contact details not available";
        if (pickupLocation === "bhopal") details = "Contact: +91 9109123408 (Bhopal)";
        else if (pickupLocation === "indore") details = "Contact: +91 91 9109123403 (Indore)";
        else if (pickupLocation === "udaipur") details = "Contact: +91 9109123403 (Udaipur)";
        setContactDetails(details);
        const timer = setTimeout(() => {
            setOpenPopup(true);
        }, 1000);

        return () => clearTimeout(timer);
    }, [bookingData]);

    if (!bookingData) {
        return (
            <Container maxWidth="sm" sx={{ py: 4 }}>
                <Paper elevation={3} sx={{ p: 4, textAlign: "center" }}>
                    <Typography variant="h6" color="error">
                        No booking data available. Please try again.
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => navigate("/")}
                        sx={{ mt: 2 }}
                    >
                        Go Back
                    </Button>
                </Paper>
            </Container>
        );
    }

    const {
        pickupDate,
        dropDate,
        pickupTime,
        dropTime,
        pickupLocation,
        dropLocation,
        rateType,
        bookingStatus,
        adminApprovalStatus,
        managerApprovalStatus,
        distanceAllowed,
        extraKmRate,
        totalAmount,
        paymentStatus,
        cancellationPolicy,
        contactNumber,
    } = bookingData;
    const selectedVehicle = JSON.parse(localStorage.getItem('selectedVehicle'));




    const handleClosePopup = () => {
        setOpenPopup(false);
    };

    return (

        <>
            <SEOHelmet title="Booking Confirmation | TSJ Car Rentals" />

            <Container maxWidth="md" sx={{ py: 4 }}>

                <Paper elevation={3} sx={{ p: 4, borderRadius: 2 }}>
                    <Box display="flex" alignItems="center" mb={2}>
                        <CheckCircleIcon color="success" sx={{ fontSize: 40, mr: 2 }} />
                        <Typography variant="h5" fontWeight="bold">
                            Booking successful!
                        </Typography>
                    </Box>

                    <Divider sx={{ my: 2 }} />

                    <Grid2 container spacing={2}>
                        <Grid2 item size={{ xs: 12, sm: 6 }}>
                            <Typography variant="body1" fontWeight="bold" textAlign={"left"}>
                                Pickup Location:
                            </Typography>
                            <Typography variant="body2" textAlign={"left"}>{pickupLocation}</Typography>
                        </Grid2>
                        <Grid2 item size={{ xs: 12, sm: 6 }}>
                            <Typography variant="body1" fontWeight="bold" textAlign={"left"}>
                                Drop Location:
                            </Typography>
                            <Typography variant="body2" textAlign={"left"}>{dropLocation}</Typography>
                        </Grid2>
                        <Grid2 item size={{ xs: 12, sm: 6 }}>
                            <Typography variant="body1" fontWeight="bold" textAlign={"left"}>
                                Pickup Date & Time:
                            </Typography>
                            <Typography variant="body2" textAlign={"left"}>
                                {new Date(pickupDate).toLocaleDateString()} at {pickupTime}
                            </Typography>
                        </Grid2>
                        <Grid2 item size={{ xs: 12, sm: 6 }}>
                            <Typography variant="body1" fontWeight="bold" textAlign={"left"}>
                                Drop Date & Time:
                            </Typography>
                            <Typography variant="body2" textAlign={"left"}>
                                {new Date(dropDate).toLocaleDateString()} at {dropTime}
                            </Typography>
                        </Grid2>
                        <Grid2 item size={{ xs: 12, sm: 6 }}>
                            <Typography variant="body1" fontWeight="bold" textAlign={"left"}>
                                Rate Type:
                            </Typography>
                            <Typography variant="body2">{rateType}</Typography>
                        </Grid2>
                        <Grid2 item size={{ xs: 12, sm: 6 }}>
                            <Typography variant="body1" fontWeight="bold" textAlign={"left"}>
                                Distance Allowed:
                            </Typography>
                            <Typography variant="body2">{distanceAllowed} km</Typography>
                        </Grid2>
                        <Grid2 item size={{ xs: 12, sm: 6 }}>
                            <Typography variant="body1" fontWeight="bold" textAlign={"left"}>
                                Extra Km Rate:
                            </Typography>
                            <Typography variant="body2" textAlign={"left"}>{extraKmRate} per km</Typography>
                        </Grid2>
                        <Grid2 item size={{ xs: 12, sm: 6 }}>
                            <Typography variant="body1" fontWeight="bold" textAlign={"left"}>
                                Total Amount:
                            </Typography>
                            <Typography variant="body2" textAlign={"left"}>&#8377;{totalAmount}</Typography>
                        </Grid2>
                        <Grid2 item size={{ xs: 12, sm: 6 }}>
                            <Typography variant="body1" fontWeight="bold" textAlign={"left"}>
                                Payment Status:
                            </Typography>
                            <Typography variant="body2" textAlign={"left"}>{paymentStatus}</Typography>
                        </Grid2>
                        <Grid2 item size={{ xs: 12, sm: 6 }}>
                            <Typography variant="body1" fontWeight="bold" textAlign={"left"}>
                                Contact Number:
                            </Typography>
                            <Typography variant="body2" textAlign={"left"}>{contactNumber}</Typography>
                        </Grid2>
                        <Grid2 item size={{ xs: 12 }}>
                            <Typography variant="body1" fontWeight="bold" textAlign={"left"}>
                                Cancellation Policy:
                            </Typography>
                            <Typography variant="body2" textAlign={"left"}>{cancellationPolicy}</Typography>
                        </Grid2>
                        <Grid2 item size={{ xs: 12 }}>
                            <Typography variant="body1" fontWeight="bold" textAlign={"left"}>
                                Approval Status:
                            </Typography>
                            <Typography variant="body2" textAlign={"left"}>
                                Admin: {adminApprovalStatus} | Manager: {managerApprovalStatus}
                            </Typography>
                        </Grid2>
                    </Grid2>

                    <Divider sx={{ my: 2 }} />

                    <Box display="flex" justifyContent="center">
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={() => navigate("/user/profile")}
                        >
                            Go to Dashboard
                        </Button>
                    </Box>
                </Paper>

                <Dialog open={openPopup} onClose={handleClosePopup}>
                    <DialogTitle>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Typography variant="h6">Booking Confirmed</Typography>
                            <IconButton onClick={handleClosePopup}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        <Typography variant="body1">
                            Your booking was successful! Our team will contact you within 2-3 hours.
                        </Typography>
                        <Typography variant="body1" sx={{ mt: 2 }}>
                            {contactDetails}
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClosePopup} color="primary" autoFocus>
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
            </Container>
        </>
    );

};

export default BookingConfirmation;
