import React from "react";
import { CiMail } from "react-icons/ci";
import { IoCallOutline } from "react-icons/io5";
import { CiLocationOn } from "react-icons/ci";
import { MdAccessTime } from "react-icons/md";
import Footer from "../Components/common/Footer";
import Navbar from "../Components/common/Navbar";
import SEOHelmet from "../Components/common/SeoHelmet";

const ContactUsPage = () => {
    const contactDetails = [
        {
            location: "Udaipur Office",
            details: [
                { icon: <CiMail className="text-xl" />, text: "info@thestandardjourney.com" },
                { icon: <IoCallOutline className="text-xl" />, text: "+91 9109123405" },
                { icon: <MdAccessTime className="text-xl" />, text: "Everyday : 08.00-21.00" },
                { icon: <CiLocationOn className="text-xl" />, text: "E 55 Subcity Center, Near Income Tax Office, Udaipur, Rajasthan 313001" },
            ],
            mapSrc:
                "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3628.8393959196114!2d73.707439!3d24.560211499999994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3967ef70c2db42bb%3A0x4d7f0dfafe50efc!2sSub%20City%20Center%20Rd%2C%20Central%20Area%2C%20Udaipur%2C%20Rajasthan%20313001!5e0!3m2!1sen!2sin!4v1728628792617!5m2!1sen!2sin",
        },
        {
            location: "Bhopal Office",
            details: [
                { icon: <CiMail className="text-xl" />, text: "info@thestandardjourney.com" },
                { icon: <IoCallOutline className="text-xl" />, text: "+91 9109123406" },
                { icon: <MdAccessTime className="text-xl" />, text: "Everyday : 08.00-21.00" },
                { icon: <CiLocationOn className="text-xl" />, text: "Plot No 195, MP Nagar Zone 2, Sargam Bus Stop, near Veer Street, Bhopal, Madhya Pradesh 462001" },
            ],
            mapSrc:
                "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3683.4027457386055!2d77.4360372!3d23.232324899999994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x397c426b3a4a7ddf%3A0x60bdaf8d8c982c5a!2sMP%20Nagar%20Zone-II%2C%20Madhya%20Pradesh%20462001!5e0!3m2!1sen!2sin!4v1728628792618!5m2!1sen!2sin",
        },
        {
            location: "Indore Office",
            details: [
                { icon: <CiMail className="text-xl" />, text: "info@thestandardjourney.com" },
                { icon: <IoCallOutline className="text-xl" />, text: "+91 9109123404" },
                { icon: <MdAccessTime className="text-xl" />, text: "Everyday : 08.00-21.00" },
                { icon: <CiLocationOn className="text-xl" />, text: "Regency Arcade, 16 G, beside Chartered Bus, Indore, Madhya Pradesh 452001" },
            ],
            mapSrc:
                "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3680.9044657396075!2d75.870371!3d22.7195681!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396257593db9e15f%3A0x4b216a4856c0cdb9!2sRegency%20Arcade%2C%20Indore%2C%20Madhya%20Pradesh!5e0!3m2!1sen!2sin!4v1728628792619!5m2!1sen!2sin",
        },
    ];

    return (
        <section className="">
            <SEOHelmet title="Contact Us | TSJ Car Rentals" />
            <Navbar />
            <div className="md:h-[35rem] h-[20rem]">
                <img src={"../assets/conatct_us_banner.png"} className="w-[100%] h-full" alt="Contact Us Banner" />
            </div>
            <div className="w-[90vw] md:w-[80vw] lg:w-[70vw] mx-auto md:px-4 lg:px-8 grid gap-10">
                <h1 className="text-2xl lg:text-4xl font-semibold mt-2">Contact Us</h1>
                <p className="mt-2 text-[15px]">
                    If you need consultation with us, you can write a message or call us, we will respond as quickly as possible.
                </p>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 my-10">
                    {contactDetails.map((office, index) => (
                        <div
                            key={index}
                            className="w-full bg-[#0A223D] text-white flex flex-col justify-between col-span-1"
                        >
                            <div className="p-4 h-full text-start flex flex-col justify-between">
                                <h2 className="text-red-500 font-bold text-sm">{office.location}</h2>

                                <div className="mt-6 space-y-4 text-start">
                                    {office.details.map((detail, idx) => (
                                        <div key={idx} className="flex space-x-2 items-center">
                                            <span className="text-[#4361EE] w-5">{detail.icon}</span>
                                            <span>{detail.text}</span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

            </div>
            <Footer />
        </section>
    );
};

export default ContactUsPage;
