
import api from "./apiUtility";


export const getTourAndTravelList = async () => {
    try {
        const response = await api.get("/get-all-tour-and-package-bookings");
        return response;
    } catch (error) {
        throw error
    }
}

export const getTaxiList = async () => {
    try {
        const response = await api.get("/get-all-taxi-bookings");
        return response;
    } catch (error) {
        throw error
    }
} 