import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Box } from "@mui/material";
import backCar from "../../Assests/newHomeImgs/home-banner.jpg";
import tour from "../../Assests/services/tour.png";
import { BASE_API } from "../../Services/apis";
import taxibackground from "../../Assests/services/taxibackground.png";          
import BookingDate from "../Core/Home/BookingDate";
import { useLocation } from "react-router-dom";
import ShowBookingDetail from "./ShowBookingDetail";
import { vehicleTypes } from "../../data/HomePageData";

const Banner = () => {
    const location = useLocation();
    const [selectedVehicleType, setSelectedVehicleType] = useState({ img: "", serviceType: "Rental", _id: "6773d6253533683ad70e5603" });
    const [loading, setLoading] = useState(false);
    const [servicesType, setServicesType] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const fuelResponse = await fetch(`${BASE_API}/all-service-type-list`);
                if (!fuelResponse.ok) throw new Error('Failed to fetch fuel types');
                const ServiceTypes = await fuelResponse.json();
                if (ServiceTypes?.data && Array.isArray(ServiceTypes.data)) {
                    const updatedData = ServiceTypes.data.map((item, index) => ({
                        ...item,
                        img: vehicleTypes[index]?.img || null,
                        Wimg: vehicleTypes[index]?.Wimg || null,
                    }));
                    setServicesType(updatedData);
                } else {
                    console.error('Service types data is not in the expected format:', ServiceTypes);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    // Function to determine the background image based on the selected vehicle type
    const getBackgroundImage = () => {
        const { serviceType } = selectedVehicleType;

        switch (serviceType) {
            case "Rental":
                console.log(backCar)
                return backCar;
            case "Tour and Travel":
                console.log(tour)

                return tour;
            case "Taxi Packages":
                console.log(taxibackground)

                return taxibackground;
            default:
                return backCar;
        }
    };

    return (
        <Box
            component={motion.div}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1.5 }}
            sx={{
                width: "100%",
                height: { xs: "20.5vh", sm: "30.5vh", md: "50vh", lg: "60vh" }, // Set responsive heights
                backgroundImage: {

                    xs: "none",
                    sm: `url(${getBackgroundImage()})`,
                    md: `url(${getBackgroundImage()})`,
                    lg: `url(${getBackgroundImage()})`,
                },
                backgroundSize: { sm: "cover", md: "cover", lg: "cover" },
                backgroundPosition: { sm: "center", md: "center", lg: "center" },
                // overflowX: { sm: "none", md: " hidden" },
                position: { md: "relative", lg: "relative" },
                display: "block",
            }}
        >
            {/* Overlay */}
            {/* <motion.div
                className="absolute inset-0 bg-black bg-opacity-60"
                initial={{ opacity: 0 }}
                animate={{ opacity: 0.6 }}
                transition={{ duration: 2 }}
            /> */}

            {/* Additional content here */}
            <motion.div
                className="relative  z-10 h-full"
                initial={{ y: 50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 1, delay: 0.5 }}
            >
                <motion.div
                    className={`lg:absolute md:absolute sm:relative ${location.pathname === "/" ? 'h-[250px] md:top-[10rem] md:right-5 md:bottom-10 md:max-w-[95vw] md:min-w-[85vw] lg:right-10 lg:bottom-10 lg:max-w-[80vw] lg:min-w-[80vw] ' : 'h-[90px] lg:right-36 lg:bottom-28 lg:max-w-[80vw] lg:min-w-[65vw]  md:right-28 md:bottom-20 md:max-w-[80vw] md:min-w-[55vw] '}`}
                    initial={{ x: 100, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ duration: 1, delay: 0.8 }}
                >
                    {location.pathname === '/'
                        ? <BookingDate
                            selectedVehicleType={selectedVehicleType}
                            setSelectedVehicleType={setSelectedVehicleType}
                            servicesType={servicesType}
                        /> :
                        <ShowBookingDetail />}
                </motion.div>
            </motion.div>
        </Box>
    );
};

export default Banner;
