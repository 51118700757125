import { useSelector } from "react-redux"
import { Navigate } from "react-router-dom"
export const Protected = ({ children }) => {

    const { user } = useSelector((state) => state.auth);
    if (user !== null) {
        return children
    }
    else {
        return <Navigate to="/login"></Navigate>
    }
}