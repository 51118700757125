import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    signupData: {
        name: '',
        mobile: '',
        dob: '',
        email: '',
        nationality: 'INDIAN',
        address: '',
        orderId: localStorage.getItem("orderId") ? localStorage.getItem("orderId") : null,
    },
    loading: false,
};

const signupSlice = createSlice({
    name: 'signup',
    initialState,
    reducers: {
        setSignupData: (state, action) => {
            state.signupData = { ...state.signupData, ...action.payload };
        },

        
        setLoading: (state, action) => {
            state.loading = action.payload; // Set loading state
        },
    },
});

export const { setSignupData,  setLoading } = signupSlice.actions;
export default signupSlice.reducer;