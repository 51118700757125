import React from 'react'
import { PDFExport } from '@progress/kendo-react-pdf';
import { Link } from '@mui/material';
import {
    Box,
    Grid2,
    Typography, Table, TableBody, TableCell, TableRow
} from "@mui/material";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { formatDate } from '../../utils/FormateValue';

const theme = createTheme({
    components: {
        MuiTableCell: {
            styleOverrides: {
                root: {
                    padding: '5px',
                },
            },
        },
    },
});

const InvocePDF = ({pdfExportComponent,savedInvoiceData}) => {

    return (
        <ThemeProvider theme={theme}>
            <PDFExport ref={pdfExportComponent} paperSize="A4">
                <div style={{ padding: '20px', fontFamily: 'Arial', fontSize: '14px', color: '#000', border: "1px solid", }}>
                    <Grid2 container>
                        <Grid2 item size={{ xs: 6 }}>
                            <img src={"/assets/company_logo.jpeg"} alt="Logo" style={{ height: '50px' }} />

                            <div style={{ marginTop: '20px', textAlign: "left" }}>
                                <strong>TSJ Cars Rental Services PVT LTD</strong>
                                <p>16 G Regency Arched Navratan Bag</p>
                                <p>Beside AICTSL Indore MP (452001)</p>
                                <p>Email: thestandardjourney@gmail.com</p>
                                <p>Website: tsj</p>
                            </div>
                        </Grid2>
                        <Grid2 item size={{ xs: 6 }} align="right">
                            <div>
                                <Typography variant="h5" fontWeight="bold" textAlign="center">
                                    INVOICE
                                </Typography>
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell sx={{ color: "#216ba5", fontWeight: "800" }}>INVOICE NO.</TableCell>
                                            <TableCell sx={{ color: "#216ba5", fontWeight: "800" }}>DATE</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>{savedInvoiceData?.invoiceNo}</TableCell>
                                            <TableCell>{formatDate(savedInvoiceData?.date)}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell sx={{ color: "#216ba5", fontWeight: "800" }}>CUSTOMER ID</TableCell>
                                            <TableCell sx={{ color: "#216ba5", fontWeight: "800" }}>Terms</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>{savedInvoiceData?.customerId}</TableCell>
                                            <TableCell>All Terms & Conditions</TableCell>
                                        </TableRow>
                                        <TableRow sx={{ merginTop: "10px" }}>
                                            <TableCell collapse={2}>Can check our website:-<Link to="https://www.thestandardjourney.com">www.thestandardjourney.com </Link></TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </div>
                        </Grid2>
                    </Grid2>

                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px', border: "1px solid", padding: "10px" }}>
                        <div>
                            <strong>BILL TO</strong>
                            <p>{savedInvoiceData?.billTo}</p>
                        </div>
                        <div style={{ borderLeft: "1px solid" }}>
                            <strong>SHIP TO</strong>
                            <p>{savedInvoiceData?.shipTo}</p>
                        </div>
                    </div>

                    <table style={{ width: '100%', marginTop: '20px', borderCollapse: 'collapse' }}>
                        <thead>
                            <tr>
                                <th style={{ border: '1px solid #000', padding: '5px' }}>DESCRIPTION</th>
                                <th style={{ border: '1px solid #000', padding: '5px' }}></th>
                                <th style={{ border: '1px solid #000', padding: '5px' }}>AMOUNT</th>
                            </tr>
                        </thead>
                        <tbody>

                            {savedInvoiceData?.items.map((item, index) => (
                                <tr key={index}>
                                    <td style={{ border: '1px solid #000', padding: '5px' }}>{item.description}</td>
                                    <td style={{ border: '1px solid #000', padding: '5px', textAlign: 'right' }}>{item.amount}</td>
                                    <td style={{ border: '1px solid #000', padding: '5px', textAlign: 'right' }}>{item.amount}</td>
                                </tr>
                            ))}
                            <tr>
                                <td style={{ border: '1px solid #000', padding: '5px' }}>FROM {formatDate(savedInvoiceData?.fromDate)} - {formatDate(savedInvoiceData?.toDate)}</td>
                                <td style={{ border: '1px solid #000', padding: '5px', textAlign: 'right' }}></td>
                                <td style={{ border: '1px solid #000', padding: '5px', textAlign: 'right' }}>0</td>
                            </tr>
                            <tr>
                                <td style={{ border: '1px solid #000', padding: '5px' }}>TAX AND TOLL</td>
                                <td style={{ border: '1px solid #000', padding: '5px', textAlign: 'right' }}></td>
                                <td style={{ border: '1px solid #000', padding: '5px', textAlign: 'right' }}>{savedInvoiceData?.toll}</td>
                            </tr>
                        </tbody>
                    </table>

                    <Grid2 container sx={{ marginTop: "5px" }}>
                        <Grid2 item size={{ xs: 6 }} sx={{ border: "1px solid" }}>
                            <div style={{ marginTop: '20px' }}>
                                <p>TSJ Cars Rental Service PVt LTD</p>
                                <p>Thank You</p>
                            </div>
                        </Grid2>

                        <Grid2 item size={{ xs: 6 }} sx={{ border: "1px solid" }}>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell sx={{ color: "#000", fontWeight: "600" }}>SUBTOTAL</TableCell>
                                        <TableCell>{savedInvoiceData?.items.reduce((acc, item) => acc + parseFloat(item.amount || 0), 0)}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{ color: "#000", fontWeight: "600" }} > DISCOUNT AMOUNT</TableCell>
                                        <TableCell>{savedInvoiceData?.discountAmount}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{ color: "#000", fontWeight: "600" }}>DISCOUNT %</TableCell>
                                        <TableCell>{savedInvoiceData?.discountPercent}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{ color: "#000", fontWeight: "600" }}>TAX RATE %</TableCell>
                                        <TableCell>{savedInvoiceData?.taxRate}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{ color: "#000", fontWeight: "600" }}>TOLL TAX</TableCell>
                                        <TableCell>{savedInvoiceData?.toll}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{ color: "#000", fontWeight: "600" }}>OTHER</TableCell>
                                        <TableCell >{savedInvoiceData?.otherCharges}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{ color: "#000", fontWeight: "600" }}>Total</TableCell>
                                        <TableCell >  {(() => {
                                            const subtotal = savedInvoiceData?.items.reduce((acc, item) => acc + parseFloat(item.amount || 0), 0);
                                            const discount = savedInvoiceData?.discountAmount ? parseFloat(savedInvoiceData?.discountAmount || 0) : (subtotal * parseFloat(savedInvoiceData?.discountPercent || 0)) / 100;
                                            const tax = ((subtotal - discount) * parseFloat(savedInvoiceData?.taxRate || 0)) / 100;
                                            return (subtotal - discount + tax + parseFloat(savedInvoiceData?.toll || 0) + parseFloat(savedInvoiceData?.otherCharges || 0)).toFixed(2);
                                        })()}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{ color: "#000", fontWeight: "600" }}>Remarks/ Instruction</TableCell>
                                        <TableCell >{savedInvoiceData?.remarks}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Grid2>
                    </Grid2>
                    <Grid2 container>
                        <Grid2 item size={8}>
                            <footer style={{ marginTop: '20px', textAlign: 'center' }}>
                                <p>For questions concerning this invoice, please contact:</p>
                                <p>The Standard Journey, (07311) 4035356, standardjourney@gmail.com</p>
                                <p>www.thestandardjourney.com</p>
                            </footer>
                        </Grid2>
                        <Grid2 item size={4}>
                            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                                <img src={"/assets/sign.png"} alt="Signature" style={{ height: '100px', width: "100px" }} />
                            </Box>
                        </Grid2>
                    </Grid2>
                </div>
            </PDFExport>
        </ThemeProvider>
    )
}

export default InvocePDF
