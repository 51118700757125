import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import image from "../../Assests/Home/image 14.png";
import girl from "../../Assests/myChoose/girl.png";
import { Link } from "react-router-dom";
import axios from "axios";
import { BASE_API } from "../../Services/apis";
import { useSelector } from "react-redux";

const TripNavbar = () => {
    const location = useLocation();
    const { user, token } = useSelector((state) => state?.auth);
    const [userProfile, setUserProfile] = useState('');

    useEffect(() => {
        setUserProfile(user);

        const fetchUserProfile = async () => {
            try {
                const response = await axios.get(`${BASE_API}/user/my-profile`, {
                    headers: {
                        Authorization: `Bearer ${token}` // Pass token in headers
                    }
                });
                setUserProfile(response.data.userData);  // Assuming the API returns the user profile data in `response.data`
            } catch (error) {
                console.error("Error fetching profile data", error);
            }
        };

        if (token) {
            fetchUserProfile();  // Only fetch profile if the user is authenticated
        }
    }, [token]);


    return (
        <div className=" xsm:w-[100vw] xsm:justify-between md:w-[65vw] mx-auto flex justify-between items-center">
            <Link to={"/"}>
                <img src={image} className="w-[50px] h-[50px]" alt="Logo" />
            </Link>

            {location.pathname === "/trip-details" && (
                <p>trip-details</p>
            )}

            <div className="xsm:w-[280px] md:w-[150px] flex h-full items-center">
                <img src={girl} className="w-[30px] h-[30px] mx-3" alt="User" />
                <div className="max-w-[100px] text-start">
                    {userProfile ? (
                        <p className="w-[110px] text-[13px] font-lato font-semibold">
                            {userProfile.name || 'User Name'} <br /> 
                            {userProfile.email || 'Email'}
                        </p>
                    ) : (
                        <p className="w-[110px] text-[13px] font-lato font-semibold">
                            Login or <br /> Create Account
                        </p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default TripNavbar;
