import React, { useState, useEffect } from "react";
import { FaSearch } from "react-icons/fa";
import axios from "axios";
import { useLocation } from "react-router-dom";
import "./MainSection.css";
import TravelCardList from "./TravelCard";
import { budgetRanges } from "../../../data/Tour&Travels";
import { BASE_API } from "../../../Services/apis";

const MainSection = () => {
    const location = useLocation();
    const cities = ["indore", "Goa", "Agatti", "Gokarna", "Dubai", "Mumbai"];
    const [TourandTravelAddData, setTourAndTravelAllData] = useState("");
    const [isMobileFilterOpen, setMobileFilterOpen] = useState(false);

    const [filters, setFilters] = useState({
        duration: 0,
        searchQuery: "",
        defaultBudget: 12000,
        budget: {
            id: 1,
            label: "₹20,000",
            min: 20000,
            max: 200000,
            count: 60,
        },
        isCitiesOpen: true,
        isPackageTypeOpen: true,
        isPremiumPackagesOpen: true,
        destination: "",
        source: "",
    });

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const initialPickupLocation = params.get("pickupLocation") || "Indore";
        const initialDestination = params.get("dropLocation") || "";
        const initialMinPrice = Number(params.get("minPrice")) || 20000;
        const initialMaxPrice = Number(params.get("maxPrice")) || 200000;
        const initialDuration = Number(params.get("durationInNight")) || 0;

        setFilters((prevFilters) => ({
            ...prevFilters,
            source: initialPickupLocation,
            destination: initialDestination,
            budget: {
                ...prevFilters.budget,
                min: initialMinPrice,
                max: initialMaxPrice,
            },
            ...(initialDuration > 0 && { duration: initialDuration }),
        }));
    }, [location.search]);

    const fetchTourAndTravels = async (params) => {
        try {
            const queryParams = new URLSearchParams(params).toString();
            const response = await axios.get(`${BASE_API}/search-tour-and-packages?${queryParams}`);
            if (response.status === 200) {
                setTourAndTravelAllData(response?.data?.tourPackages);
            } else {
                setTourAndTravelAllData([])
            }
        } catch (error) {
            setTourAndTravelAllData([])
            console.error(error.message);
        }
    };

    const updateSearchParams = (newParams) => {
        const url = new URL(window.location);
        Object.keys(newParams).forEach((key) => {
            if (newParams[key] !== null && newParams[key] !== undefined) {
                url.searchParams.set(key, newParams[key]);
            } else {
                url.searchParams.delete(key);
            }
        });
        window.history.replaceState(null, "", url);
    };

    useEffect(() => {
        const params = {
            pickupLocation: filters.source,
            dropLocation: filters.destination,
            minPrice: filters.budget.min,
            maxPrice: filters.budget.max,
            ...(filters.duration > 0 && { durationInDays: filters.duration }),
            ...(filters.duration > 0 && { durationInNight: filters.duration - 1 }),
        };
        const paylod = {
            source: filters.source,
            destination: filters.destination,
            minPrice: filters.budget.min,
            maxPrice: filters.budget.max,
            ...(filters.duration > 0 && { durationInDays: filters.duration }),
            ...(filters.duration > 0 && { durationInNight: filters.duration - 1 }),

        }

        updateSearchParams(params);
        fetchTourAndTravels(paylod);
    }, [filters]);

    const handleFilterChange = (key, value) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            [key]: value,
        }));
    };

    const handleCityChange = (city) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            destination: city,
        }));
    };

    const filteredCities = cities.filter((city) =>
        city.toLowerCase().includes(filters.searchQuery.toLowerCase())
    );


    return (
        <section
            className="absolute shadow-lg xsm:top-[35rem] sm:top-[32rem] md:top-[58.8vh]  left-1/2 transform -translate-x-1/2 rounded-2xl w-[95vw] lg:w-[70vw] z-50 h-auto lg:h-[50rem] mx-auto"
            style={{ marginTop: "-7rem" }}
        >
            {/* Header */}
            <div className="h-[4rem] rounded-t-2xl w-full flex border-b-2 border-gray-300 bg-white shadow">
                <div className="w-full md:max-w-[25%] flex items-center  border-r-2 border-gray-300 p-3 md:p-5 px-4 md:px-10 text-start">
                    <button
                        onClick={() => setMobileFilterOpen(!isMobileFilterOpen)}
                        className="bg-blue-500  md:hidden   text-[0.8rem] md:text-[0.9rem]  text-white px-2 py-2 rounded-lg shadow-lg"
                    >
                        {isMobileFilterOpen ? "Close Filters" : "Open Filters"}
                    </button>

                    <h3 className="font-semibold text-blue-500 py-3 md:py-5 pl-5 md:pl-10 text-start">
                        Filters
                    </h3>

                </div>
                <div className="w-full md:max-w-[75%]">
                    <h3 className="font-semibold text-blue-500 py-3 md:py-5 pl-5 md:pl-10 text-start">
                        All Packages
                    </h3>
                </div>
            </div>

            {/* Main Content */}
            <div className="w-full flex flex-col lg:flex-row rounded-b-2xl">
                {/* Filters Section */}
                <div
                    className={`lg:block ${isMobileFilterOpen ? "block" : "hidden"} lg:max-w-[25%] h-[60vh] lg:h-[730px] bg-white shadow-lg overflow-y-scroll border-r-2 rounded-bl-xl border p-3 lg:p-5 px-5 lg:px-10 custom-scrollbar-hidden`}
                >
                    {/* Duration Filter */}
                    <div className="mb-6">
                        <h3 className="font-bold text-black text-[1.15rem]">Duration (in Nights)</h3>
                        <div className="flex justify-around text-sm">
                            <span className="text-md text-blue-600 font-semibold">
                                <span className="text-black">{filters.duration}</span> Days
                            </span>
                            <span className="text-md text-blue-600 font-semibold">
                                <span className="text-black">{filters.duration > 0 ? filters.duration - 1 : 0}</span> Nights
                            </span>
                        </div>
                        <input
                            type="range"
                            min="2"
                            max="7"
                            value={filters.duration}
                            onChange={(e) => handleFilterChange("duration", parseInt(e.target.value))}
                            className="w-full mt-2 h-1 rounded-lg cursor-pointer"
                        />
                        <div className="flex justify-between text-gray-600 mt-2 text-xs">
                            <span>1N</span>
                            <span>6N</span>
                        </div>
                    </div>

                    {/* Budget Filter */}
                    <div className="mb-6">
                        <h3 className="font-bold text-black text-[1.15rem]">Budget (per person)</h3>
                        <div className="flex justify-between text-xs text-gray-600 mt-2">
                            <span>₹12,000</span>
                            <span>₹200,000</span>
                        </div>
                        <div className="mt-4">
                            {budgetRanges.map((range) => (
                                <label key={range.id} className="flex justify-between items-center mb-2">
                                    <div>
                                        <input
                                            type="checkbox"
                                            className="mr-2 accent-blue-500"
                                            checked={filters.budget.id === range.id}
                                            onChange={() =>
                                                handleFilterChange(
                                                    "budget",
                                                    filters.budget.id === range.id
                                                        ? { id: 1, label: "₹20,000", min: 20000, max: 200000, count: 60 }
                                                        : range
                                                )
                                            }
                                        />
                                        {range.label}
                                    </div>
                                    <span className="text-gray-500 text-sm">({range.count})</span>
                                </label>
                            ))}
                        </div>
                    </div>

                    {/* Cities Filter */}
                    <div className="mb-4">
                        <button
                            onClick={() => handleFilterChange("isCitiesOpen", !filters.isCitiesOpen)}
                            className="w-full text-left text-lg font-semibold flex justify-between items-center"
                        >
                            Cities <span>{filters.isCitiesOpen ? "-" : "+"}</span>
                        </button>
                        {filters.isCitiesOpen && (
                            <div>
                                <div className="flex items-center bg-gray-100 rounded px-2 py-1 mt-2">
                                    <FaSearch className="text-gray-500" />
                                    <input
                                        type="text"
                                        placeholder="Search"
                                        value={filters.searchQuery}
                                        onChange={(e) => handleFilterChange("searchQuery", e.target.value)}
                                        className="bg-transparent pl-2 focus:outline-none w-full"
                                    />
                                </div>
                                <div className="mt-2">
                                    {filteredCities.length > 0 ? (
                                        filteredCities.map((city, index) => (
                                            <div key={index} className="flex items-center space-x-2">
                                                <input
                                                    type="checkbox"
                                                    checked={filters.destination === city}
                                                    onChange={() => handleFilterChange("destination", city)}
                                                />
                                                <label className="w-full flex justify-between">
                                                    {city}
                                                    <span>({index + 1})</span>
                                                </label>
                                            </div>
                                        ))
                                    ) : (
                                        <p className="text-gray-500 text-sm mt-2">No cities found</p>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                {/* Packages List */}
                <div className="w-full lg:max-w-[75%] h-[60vh] lg:h-[730px] overflow-y-scroll custom-scrollbar-hidden">
                    <TravelCardList packages={TourandTravelAddData} />
                </div>



            </div>
        </section>);
};

export default MainSection;
