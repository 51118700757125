import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { useSearchParams } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux"
import axios from "axios"
import { toast } from "react-hot-toast"
import { BASE_API } from "../../../Services/apis"
const BlueBar = ({ selectedVehicle }) => {
console.log(selectedVehicle)
    const location = useLocation();
    const navigate = useNavigate()
    const { vehicleType } = useParams();
    const { token, user } = useSelector((state) => state.auth);
    const [selectedPayment, setSelectedPayment] = useState("part");
    const [searchParams, setSearchParams] = useSearchParams();
    const [urlData, setUrlData] = useState({
        fromLocation: '',
        toLocation: '',
        pickupDate: '',
        pickupTime: '',
        dropoffDate: '',
        dropoffTime: '',
        fuelType: '',
        vehicleModel: '',
    });


    useEffect(() => {
        // Parse query parameters from the URL
        let queryParams = new URLSearchParams(location.search);

        const fromLocation = queryParams.get("pickupLocation");
        const toLocation = queryParams.get("dropLocation");
        const pickupDate = queryParams.get("pickupDate");
        const pickupTime = queryParams.get("pickupTime");
        const dropoffDate = queryParams.get("dropoffDate");
        const dropoffTime = queryParams.get("dropoffTime");
        const fuelType = queryParams.get("fuelType");
        const vehicleModel = queryParams.get("vehicleModel");
        console.log(queryParams.get("pickupDate"))

        // Set the query parameters to the state
        setUrlData({
            fromLocation,
            toLocation,
            pickupDate,
            pickupTime,
            dropoffDate,
            dropoffTime,
            fuelType,
            vehicleModel
        });
    }, [location.search]);


    const handleSetQueary = (name, value) => {
        // Merge the new parameter with the existing ones
        const newSearchParams = new URLSearchParams(searchParams);
        console.log(newSearchParams);
        newSearchParams.set(name, value);

        // Update the URL query parameters
        setSearchParams(newSearchParams);

        // Update the corresponding state value
        setUrlData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };


    // Payments   ------->>>>>>

    const [bookingDetails, setBookingDetails] = useState({
        vehicleId: "",
        pickupDate: "",
        dropDate: "",
        pickupTime: "",
        dropTime: "",
        pickupLocation: "",
        dropLocation: "",
        rateType: "",
        totalAmount: "",
        contactNumber: '',
        additionalRequests: 'child birth',
    });


    const searchParam = new URLSearchParams(location.search);
    useEffect(() => {
        const from = searchParams.get("pickupLocation") || "";
        const to = searchParams.get("dropLocation") || "";
        const pickupDate = searchParams.get("pickupDate") || "";
        const dropoffDate = searchParams.get("dropoffDate") || "";
        const dropoffTime = searchParams.get("dropoffTime") || "";
        const selectedrateType = searchParams.get("rateType");
        const pickupTime = searchParams.get("pickupTime");


        setBookingDetails({
            vehicleId: selectedVehicle._id,
            totalAmount: selectedrateType == 'ratePerDay' ? selectedVehicle?.ratePerDay : selectedVehicle?.ratePer24Hrs,
            pickupDate: pickupDate,
            dropDate: dropoffDate, 
            pickupTime:pickupTime || "10:00",  
            dropTime: dropoffTime || "18:00",
            pickupLocation: from,
            dropLocation: to,
            rateType: "RatePerDay", 
            contactNumber: user?.mobile,
            additionalRequests: "London", 
        });
    }, [location.search, selectedVehicle, user]);


    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const pickupLocation = searchParams.get("pickupLocation");
        const dropLocation = searchParams.get("dropLocation");
        const pickUpDate = searchParams.get("pickupDate");
        const pickUpTime = searchParams.get("pickupTime");
        const dropOffDate = searchParams.get("dropoffDate");
        const dropOffTime = searchParams.get("dropOffTime");
        const rateTypes = searchParams.get("rateType")

        setBookingDetails({
            vehicleId: selectedVehicle._id,
            rateType: rateTypes, 
            totalAmount: rateTypes == 'ratePerDay' ? selectedVehicle?.ratePerDay : selectedVehicle?.ratePer24Hrs,
            pickupAddress: pickupLocation || "Default Pickup Address",
            pickupDate: pickUpDate,
            pickupTime: pickUpTime,
            dropAddress: dropLocation || "Default Drop Address",
            dropDate: dropOffDate || pickUpDate, 
            dropTime: dropOffTime || "16:30", 
            inBetweenCities: [
                { address: "Indore" },
                { address: "Guawalier" },
                { address: "Satna" }
            ],
            alternateMobile: user?.mobile 
        });
    }, [location.search]);


    const RentalPayment = async () => {
        const RenatalParms = {
            vehicleId: bookingDetails.vehicleId,
            pickupDate: bookingDetails.pickupDate,
            dropDate: bookingDetails.dropDate,
            pickupTime: bookingDetails.pickupTime,
            dropTime: bookingDetails.dropTime,
            pickupLocation: searchParams.get("pickupLocation"),
            dropLocation: searchParams.get("dropLocation"),
            rateType: searchParams.get("rateType") === "RatePerDay" ? "RatePerDay" : "RatePer24Hrs",
            totalAmount: bookingDetails.totalAmount,
            contactNumber: user.mobile,
            additionalRequests: bookingDetails.additionalRequests
        }
       try {
            const response = await axios.post(
                `${BASE_API}/booking/create/rental-booking`, RenatalParms,
                {
                    headers: {
                        Authorization: `Bearer ${token}`, 
                    },
                } 
            );


            if (response.status === 201 || response.status === 200) {
                toast.success('Successfully booked vehicle');
                localStorage.setItem('Booking', JSON.stringify(response?.data?.booking));
                navigate('/rental-booking-confirmation-details', { state: { booking: response?.data?.booking } });
            } else {
                toast.error('Error during booking');
            }
         } catch (error) {
            console.error("Error during booking:", error.message);
            toast.error(`Error during booking: ${error.response?.data?.message || error.message}`);
        }
    }

    const TaxiServicePackage = async () => {
        const rawInBetweenCities = searchParams.get("inBetweenCities");

        let inBetweenCities = [];
        if (rawInBetweenCities) {
            
                inBetweenCities = JSON.parse(rawInBetweenCities);
            
        }
        try {
            const response = await axios.post(
                `${BASE_API}/booking/create/taxi-booking`,
                {
                    vehicleId: bookingDetails.vehicleId,
                    pickupAddress: bookingDetails.pickupAddress,
                    pickupDate: bookingDetails.pickupDate,
                    pickupTime: bookingDetails.pickupTime,
                    dropAddress: bookingDetails.dropAddress,
                    dropDate: bookingDetails.dropDate,
                    dropTime: bookingDetails.dropTime,
                    alternateMobile: searchParams.get("alternateMobile"),
                    inBetweenCities: Array.isArray(inBetweenCities)
                        ? inBetweenCities.map((city) => ({ address: city.address }))
                        : [],
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.status === 201 || response.status === 200) {
                toast.success("Successfully booked vehicle");
                localStorage.setItem("Booking", JSON.stringify(response?.data?.booking));
                navigate('/taxi-package-booking-successful', { state: { booking: response?.data?.booking } });
            } else {
                toast.error("Error during booking");
            }
       } catch (error) {
            console.error("Error during booking:", error.message);
            toast.error(`Error during booking: ${error.response?.data?.message || error.message}`);
        }
    };

    const handleBookingVehicle = async () => {
        if (vehicleType !== "TaxiPackages") {
            RentalPayment();
        }
        else {
            TaxiServicePackage();
        }

    };

    return (
        <div className="w-full bg-[#0A223D]">
            <div className=" xsm:w-[100vw]  flex-col sm:w-[80vw] md:w-[75vw] lg:w-[65vw] mx-auto flex justify-between items-center min-h-[8rem] h-auto">
                <form className="flex flex-row  items-center p-4 h-auto gap-4 rounded-lg justify-between w-full text-start">
                    <div className="flex flex-col w-[50%]  text-white font-lato min-h-[4rem] space-y-3">
                        <h1 className="text-[1.1rem] md:text-[22px] font-lato leading-3">Review Booking</h1>
                        <div className="flex text-[0.8rem] md:text-[1rem] uppercase space-x-2 items-center">
                            <p>{urlData.fromLocation}</p>
                            <ArrowRightAltIcon />
                            <span>{urlData.toLocation}</span>
                        </div>
                        <div className="flex text-[0.8rem] md:text-[1rem] items-center">
                            <div className="font-lato font-normal  xsm:flex-col md:flex-row leading-5 text-left">
                                <span>Pickup:</span>
                                <span > {"  "}{urlData.pickupDate}{"    "}
                                    {urlData.pickupTime}</span>
                            </div>
                        </div>
                    </div>
                    <div className="flex sm:flex-col xsm:flex-col md:flex-row w-[50%] gap-4 ">
                        <div className="w-[90%] mx-auto">
                            <label className="text-white">Dropoff Date</label>
                            <input
                                type="date"
                                value={urlData.dropoffDate}
                                onChange={(e) => handleSetQueary("dropoffDate", e.target.value)}
                                className="block mt-1 px-3 md:py-2 xsm:py-1  border border-gray-300 rounded-md"
                            />
                        </div>
                        <div className="w-[90%] mx-auto">
                            <label className="text-white">Dropoff Time</label>
                            <input
                                type="time"
                                value={urlData.dropoffTime}
                                onChange={(e) => handleSetQueary("dropoffTime", e.target.value)}
                                className="block mt-1 px-3 md:py-2 xsm:py-1 w-full border border-gray-300 rounded-md"
                            />
                        </div>

                    </div>

                </form>
                {/* Payments */}
                <div className="w-[95vw] sm:w-[90vw] flex-col-reverse md:hidden lg:hidden mx-auto text-[0.8rem]   shadow-md rounded-md py-4 flex  gap-2">
                    {/* Pay Now Button */} 
                    <button
                        onClick={handleBookingVehicle}
                        style={{ background: "linear-gradient(90.95deg, #53B2FE 0%, #065AF3 100%)" }}
                        className="w-full bg-blue-500 text-white py-3 font-semibold text-lg mb-4">
                        PAY ₹{bookingDetails?.totalAmount || selectedVehicle?.ratePer12Hrs} NOW
                    </button>

                    <div className="space-y-4 p-4">
                        <label className="flex items-center">
                            <input
                                type="radio"
                                name="payment"
                                value="part"
                                checked={selectedPayment === "part"}
                                onChange={() => setSelectedPayment("part")}
                                className="mr-2"
                            />
                            <span className="flex justify-between w-full">
                                <div className="flex flex-col">
                                    <span className="text-white font-bold"> payment now ₹{bookingDetails?.totalAmount || selectedVehicle?.ratePer12Hrs}</span>


                                </div>
                            </span>
                        </label>

                        <label className="flex items-center">
                            <input
                                type="radio"
                                name="payment"
                                value="full"
                                checked={selectedPayment === "full"}
                                onChange={() => setSelectedPayment("full")}
                                className="mr-2"
                            />
                            <span className="flex justify-between w-full">
                                <div className="flex flex-col">
                                    {/* <span className="text-white font-bold">Make full payment now ₹{bookingDetails.totalAmount}</span> */}
                                    <span className="text-white font-bold">Pay After</span>
                                </div>
                            </span>
                        </label>
                    </div>
                    {/* Total Amounts */}
                    <div className="border-t border-gray-300 mt-4 w-[90%] mx-auto flex items-center justify-between ">
                        <div className="flex text-white  text-lg font-semibold flex-col text-start">
                            <span className="font-bold">Total Amount</span>
                            <p className="text-sm text-gray-500">inc. of tolls and taxes</p>
                        </div>
                        <div className="">
                            <span className="flex items-center justify-end">
                                {/* <div className="line-through text-gray-400">₹{selectedVehicle?}</div> */}

                                <span className="text-xl  text-white font-bold">₹{bookingDetails?.totalAmount || selectedVehicle?.ratePer12Hrs}</span>
                            </span>
                            <span className="text-green-500 font-semibold text-end">Fare .Breakup</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BlueBar;
