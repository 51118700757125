import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { addNewService, getServicesList, deleteService } from '../../Services/AddVehicleDetails';
import { Button, Box, TextField, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import SEOHelmet from '../../Components/common/SeoHelmet';

const AddNewService = () => {
    const [services, setServices] = useState([]);

    useEffect(() => {
        const fetchServices = async () => {
            try {
                const response = await getServicesList();
                setServices(response?.data?.data || []); 
            } catch (error) {
                console.error('Error fetching services:', error);
            }
        };
        fetchServices();
    }, []);

    // Formik setup for adding a new service
    const formik = useFormik({
        initialValues: {
            serviceType: '' // Field for service type
        },
        validationSchema: Yup.object({
            serviceType: Yup.string()
                .required('Service type is required')
                .min(2, 'Service type must be at least 2 characters'), // Validation for service type
        }),
        onSubmit: async (values, { resetForm }) => {
            try {
                await addNewService({ serviceType: values.serviceType }); // Add new service to the API
                setServices([...services, { serviceType: values.serviceType, _id: Date.now() }]); // Update state after adding
                resetForm(); // Clear form after successful submission
            } catch (error) {
                console.error('Error adding service type:', error);
            }
        }
    });

    // Handle Service Deletion
    const handleDelete = async (id) => {
        try {
            await deleteService(id); // Call API to delete service
            setServices(services.filter(service => service._id !== id)); // Update state after deletion
        } catch (error) {
            console.error('Error deleting service type:', error);
        }
    };

    return (
        <div style={{ maxHeight: "72vh" }}>
            <SEOHelmet title="Create New Service Type | TSJ Car Rentals"/>
            <Typography variant="h5" gutterBottom>
                Add New Service Type
            </Typography>

            {/* Form to Add New Service */}
            <Box
                component="form"
                onSubmit={formik.handleSubmit}
                sx={{
                    display: 'grid',
                    gridTemplateColumns: '3fr 1fr', // 75% for input, 25% for button
                    alignItems: 'center',
                    gap: 2,
                    marginTop: 2,
                }}
            >
                <TextField
                    id="serviceType"
                    name="serviceType"
                    label="Service Type"
                    value={formik.values.serviceType}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.serviceType && Boolean(formik.errors.serviceType)}
                    helperText={formik.touched.serviceType && formik.errors.serviceType}
                    margin="normal"
                />
                <Button color="primary" variant="contained" type="submit">
                    Add Service Type
                </Button>
            </Box>

            <TableContainer
                component={Paper}
                sx={{
                    height: '400px',
                    overflowY: 'scroll',
                }}
            >
                <Table stickyHeader aria-label="vehicle table  ">
                    <TableHead>
                        <TableRow sx={{ backgroundColor: '#1976D4' }}>
                            <TableCell sx={{ color: 'white' }}>Service Type</TableCell>
                            <TableCell sx={{ color: 'white' }}>ID</TableCell>
                            <TableCell sx={{ color: 'white' }}>Version</TableCell>
                            <TableCell sx={{ color: 'white' }}>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {services.map((service) => (
                            <TableRow key={service._id}>
                                <TableCell>{service.serviceType}</TableCell>
                                <TableCell>{service._id}</TableCell>
                                <TableCell>{service.__v}</TableCell>
                                <TableCell>
                                    <Button
                                        color="secondary"
                                        variant="outlined"
                                        onClick={() => handleDelete(service._id)}
                                    >
                                        Delete
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default AddNewService;
