import React, { useEffect, useState } from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Button,
    Box,
    MenuItem,
    Chip
} from '@mui/material';
import {
    getVehicleTypesList,
    getVehicleModelList,
    getFuelList,
    editRentalVehicle,
    getAllOnboardVehicleList
} from '../../Services/AddVehicleDetails';
import toast from 'react-hot-toast';

const EditRentalVehicleModal = ({ open, onClose, selectedVehicle, fetchVehicles }) => {
    const [vehicleTypes, setVehicleTypes] = useState([]);
    const [vehicleModels, setVehicleModels] = useState([]);
    const [fuelTypes, setFuelTypes] = useState([]);
    const [amenityInput, setAmenityInput] = useState('');
    const [locationInput, setLocationInput] = useState('');

    const [formData, setFormData] = useState({
        vehicleId: '',
        vehicleType: '',
        vehicleModel: '',
        vehicleNumber: '',
        fuelType: '',
        transmission: '',
        ratePerDay: '',
        ratePer24Hrs: '',
        limitPer24Hrs: '',
        extraHoursRate: '',
        extraKmRate: '',
        amenities: [],
        description: '',
        cancellationRule: '',
        seats: '',
        img: '',
        contactNumber: '',
        availableLocation: []
    });

    useEffect(() => {
        if (selectedVehicle) {
            setFormData({
                vehicleId: selectedVehicle._id,
                vehicleType: selectedVehicle.vehicleType || '',
                vehicleModel: selectedVehicle.vehicleModel || '',
                vehicleNumber: selectedVehicle.vehicleNumber || '',
                fuelType: selectedVehicle.fuelType || '',
                transmission: selectedVehicle.transmission || '',
                ratePerDay: selectedVehicle.ratePerDay || '',
                ratePer24Hrs: selectedVehicle.ratePer24Hrs || '',
                limitPer24Hrs: selectedVehicle.limitPer24Hrs || '',
                extraHoursRate: selectedVehicle.extraHoursRate || '',
                extraKmRate: selectedVehicle.extraKmRate || '',
                amenities: selectedVehicle.amenities || [],
                description: selectedVehicle.description || '',
                cancellationRule: selectedVehicle.cancellationRule || '',
                seats: selectedVehicle.seats || '',
                img: selectedVehicle.img || '',
                contactNumber: selectedVehicle.contactNumber || '',
                availableLocation: selectedVehicle?.availableLocation || []
            });
        }
    }, [selectedVehicle]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [vehicleTypesResponse, vehicleModelsResponse, fuelTypesResponse] = await Promise.all([
                    getVehicleTypesList(),
                    getVehicleModelList(),
                    getFuelList()
                ]);

                setVehicleTypes(vehicleTypesResponse?.data?.data || []);
                setVehicleModels(vehicleModelsResponse?.data || []);
                setFuelTypes(fuelTypesResponse?.data?.data || []);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        if (open) fetchData();
    }, [open]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleAmenityAdd = () => {
        if (amenityInput.trim() && !formData.amenities.includes(amenityInput)) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                amenities: [...prevFormData.amenities, amenityInput]
            }));
            setAmenityInput('');
        }
    };

    const handleLocationAdd = () => {
        if (locationInput.trim() && !formData.availableLocation.includes(locationInput)) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                availableLocation: [...prevFormData.availableLocation, locationInput],
            }));
            setLocationInput('');
        }
    };

    const handleAmenityRemove = (amenity) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            amenities: prevFormData.amenities.filter((item) => item !== amenity)
        }));
    };

    const handleLocationRemove = (location) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            availableLocation: prevFormData.availableLocation.filter((item) => item !== location)
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await editRentalVehicle(formData);
            if (response.status === 200) {
                await getAllOnboardVehicleList();
                toast.success('Vehicle details updated successfully');
                onClose();
                fetchVehicles()
            }
        } catch (error) {
            console.error('API Error:', error);
            toast.error('Failed to update vehicle details');
        }
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>Edit Vehicle</DialogTitle>
            <DialogContent>
                <Box component="form" onSubmit={handleSubmit} sx={{ display: 'grid', gap: 2 }}>
                    <TextField
                        label="Vehicle Type"
                        name="vehicleType"
                        select
                        value={formData.vehicleType}
                        onChange={handleChange}
                    >
                        {vehicleTypes.map((type) => (
                            <MenuItem key={type._id} value={type.vehicleType}>
                                {type.vehicleType}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        label="Vehicle Model"
                        name="vehicleModel"
                        select
                        value={formData.vehicleModel}
                        onChange={handleChange}
                    >
                        {vehicleModels.map((model) => (
                            <MenuItem key={model._id} value={model.vehicleModel}>
                                {model.vehicleModel}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField label="Vehicle Number" name="vehicleNumber" value={formData.vehicleNumber} onChange={handleChange} />
                    <TextField
                        label="Fuel Type"
                        name="fuelType"
                        select
                        value={formData.fuelType}
                        onChange={handleChange}
                    >
                        {fuelTypes.map((fuel) => (
                            <MenuItem key={fuel._id} value={fuel.fuelType}>
                                {fuel.fuelType}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        label="Transmission"
                        name="transmission"
                        select
                        value={formData.transmission}
                        onChange={handleChange}
                    >
                        <MenuItem value="Manual Transmission">Manual Transmission</MenuItem>
                        <MenuItem value="Automatic Transmission">Automatic Transmission</MenuItem>
                    </TextField>
                    <TextField label="Rate Per Day" name="ratePerDay" value={formData.ratePerDay} onChange={handleChange} />
                    <TextField label="Rate Per 24 Hours" name="ratePer24Hrs" value={formData.ratePer24Hrs} onChange={handleChange} />
                    <TextField label="Limit Per 24 Hours" name="limitPer24Hrs" value={formData.limitPer24Hrs} onChange={handleChange} />
                    <TextField label="Extra Hours Rate" name="extraHoursRate" value={formData.extraHoursRate} onChange={handleChange} />
                    <TextField label="Extra KM Rate" name="extraKmRate" value={formData.extraKmRate} onChange={handleChange} />
                    <Box>
                        <TextField
                            label="Add Amenity"
                            value={amenityInput}
                            onChange={(e) => setAmenityInput(e.target.value)}
                        />
                        <Button onClick={handleAmenityAdd}>Add</Button>
                    </Box>
                    <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                        {formData.amenities.map((amenity) => (
                            <Chip
                                key={amenity}
                                label={amenity}
                                onDelete={() => handleAmenityRemove(amenity)}
                            />
                        ))}
                    </Box>
                    <TextField label="Description" name="description" value={formData.description} onChange={handleChange} multiline rows={3} />
                    <TextField label="Cancellation Rule" name="cancellationRule" value={formData.cancellationRule} onChange={handleChange} />
                    <TextField label="Seats" name="seats" value={formData.seats} onChange={handleChange} />
                    <TextField label="Image URL" name="img" value={formData.img} onChange={handleChange} />
                    <TextField label="Contact Number" name="contactNumber" value={formData.contactNumber} onChange={handleChange} />

                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <TextField
                            label="Available Location"
                            value={locationInput}
                            onChange={(e) => setLocationInput(e.target.value)}
                            fullWidth
                        />
                        <Button variant="contained" onClick={handleLocationAdd}>Add</Button>
                    </Box>
                    <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                        {formData.availableLocation.map((location) => (
                            <Chip
                                key={location}
                                label={location}
                                onDelete={() => handleLocationRemove(location)}
                            />
                        ))}
                    </Box>

                    <DialogActions>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button type="submit">Save Changes</Button>
                    </DialogActions>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default EditRentalVehicleModal;
