import React, { useEffect, useState, useCallback } from "react";
import { RiArrowDropDownLine } from "react-icons/ri";
import { useLocation, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TimePicker from "react-time-picker";
import "react-time-picker/dist/TimePicker.css";
import { useDispatch } from 'react-redux';
import { setVehicles, setLoading, setError } from "../../slices/VehiclesSlice";
import { IoLocation } from "react-icons/io5"
import { GrLocationPin } from "react-icons/gr";
import { MdDateRange } from "react-icons/md";
import { IoIosTimer } from "react-icons/io";
const ShowBookingDetail = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();


    const [formValues, setFormValues] = useState({
        fromLocation: "",
        toLocation: "",
        vehicleTypes: "",
        selectedDate: new Date(),
        selectedTime: "",
        dropoffDate: '', // New state for dropoffDate
        dropoffTime: "", // New state for dropoffTime
        fuelType: "", // New state for fuelType
        vehicleModel: "", // New state for vehicleModel
    });


    // Handle change for any input
    const handleInputChange = (key, value) => {

        setFormValues((prevState) => ({
            ...prevState,
            [key]: value,
        }));
    };

    // Handle date change
    const handleDateChange = (field, value) => {
        console.log(field, value);
        setFormValues((prevState) => ({
            ...prevState,
            [field]: value,
        }));
    };

    // Handle time change
    const handleTimeChange = (field, value) => {
        setFormValues((prevState) => ({
            ...prevState,
            [field]: value,
        }));
    };



    // Update query parameters with the new values
    const updateQueryParameters = useCallback(() => {
        const queryParams = new URLSearchParams();
        if (formValues.fromLocation) queryParams.set("pickupLocation", formValues.fromLocation);
        if (formValues.toLocation) queryParams.set("dropLocation", formValues.toLocation);
        if (formValues.vehicleTypes) queryParams.set("vehicleType", formValues.vehicleTypes);
        if (formValues.selectedDate) queryParams.set("pickupDate", formValues.selectedDate) // Format as yyyy-mm-dd
        if (formValues.selectedTime) queryParams.set("pickupTime", formValues.selectedTime);
        if (formValues.dropoffDate) queryParams.set("dropoffDate", formValues.dropoffDate); // Format as yyyy-mm-dd
        if (formValues.dropoffTime) queryParams.set("dropoffTime", formValues.dropoffTime);
        if (formValues.fuelType) queryParams.set("fuelType", formValues.fuelType);
        if (formValues.vehicleModel) queryParams.set("vehicleModel", formValues.vehicleModel);

        navigate({
            pathname: location.pathname,
            search: queryParams.toString(),
        });
    }, [formValues, navigate, location.search]);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const from = queryParams.get("pickupLocation");
        const to = queryParams.get("dropLocation");
        const pickupDate = queryParams.get("pickupDate");
        const pickupTime = queryParams.get("pickupTime");
        const dropoffDate = queryParams.get("dropoffDate");
        const dropoffTime = queryParams.get("dropoffTime");
        const fuelType = queryParams.get("fuelType");
        const vehicleModel = queryParams.get("vehicleModel");

        setFormValues({
            fromLocation: from || "",
            toLocation: to || "",
            // vehicleTypes: vehicleType || "",
            selectedDate: pickupDate ? pickupDate : new Date(),
            selectedTime: pickupTime || "",
            dropoffDate: dropoffDate ? dropoffDate : new Date(), // Set dropoff date
            dropoffTime: dropoffTime || "", // Set dropoff time
            fuelType: fuelType || "", // Set fuel type
            vehicleModel: vehicleModel || "", // Set vehicle model
        });
    }, [location.search]);

    const handleSearch = async () => {
        updateQueryParameters(); // Update query parameters

        dispatch(setLoading(true)); // Set loading state

        try {
            const queryParams = new URLSearchParams(window.location.search);
            const pickupLocation = queryParams.get("pickupLocation");
            const dropLocation = queryParams.get("dropLocation");
            const pickupDate = queryParams.get("pickupDate");
            const pickupTime = queryParams.get("pickupTime");
            const dropoffDate = queryParams.get("dropoffDate");
            const dropoffTime = queryParams.get("dropoffTime");

            // Construct the API URL with the extracted query parameters
            const apiUrl = `https://www.thestandardjourney.com/api/v1/search-avaliable-rental-vehicle?pickupLocation=${pickupLocation}&dropLocation=${dropLocation}&pickupDate=${pickupDate}&pickupTime=${pickupTime}&dropoffDate=${dropoffDate}&dropoffTime=${dropoffTime}`;


            const response = await fetch(apiUrl); // Fetch data from API
            if (!response.ok) {
                throw new Error("Failed to fetch vehicles");
            }
            const data = await response.json();
            dispatch(setVehicles(data)); // Dispatch the data to Redux

        } catch (error) {
            console.error("Failed to fetch data:", error);
            dispatch(setError(error.message)); // Set error state
        } finally {
            dispatch(setLoading(false)); // Stop loading
        }
    };


    return (
        <section className="w-full md:h-full lg:h-full ">
            <div className="w-full bg-[#FFFFFF] relative   flex flex-col space-y-2 lg:shadow-lg xsm:h-full md:shadow-lg lg:rounded-md md:rounded-md md:h-[150px]">

                {/* web Screen UI  */}
                <section className="sm:hidden xsm:hidden xs:hidden lg:grid md:grid w-[100%]   border-[0.8px] border-[#E7E7E7] rounded-lg h-[85px] bg-white">
                    <div className="flex w-full">
                        {/* From Section */}
                        <div className="relative w-[30%] text-start border-r-[1px] font-semibold border-gray-200 p-1">
                            <label className="font-lato pl-2 text-custom font-semibold text-black">From</label>
                            <select
                                value={formValues.fromLocation}
                                className="font-lato text-[21.73px] focus:ring-0 focus:outline-none font-black leading-[25.81px] text-left w-full pl-3"
                                onChange={(e) => handleInputChange("fromLocation", e.target.value)}
                            >
                                <option className="text-[1rem]" value="" disabled>Select Location</option>
                                <option value="Udaipur">Udaipur</option>
                                <option value="Bhopal">Bhopal</option>
                                <option value="Indore">Indore</option>
                            </select>
                        </div>
                        <div className="w-[30%] text-start border-r-[1px] border-gray-200 p-1">
                            <label className="font-lato pl-2 text-custom font-semibold text-gray-600">To</label>
                            <input
                                type="text"
                                placeholder="Destination"
                                value={formValues.toLocation}
                                className="font-lato text-[21.73px] focus:ring-0 focus:outline-none placeholder:font-semibold font-black leading-[25.81px] text-left w-full pl-3"
                                onChange={(e) => handleInputChange("toLocation", e.target.value)}
                            />
                        </div>

                        <div className="w-[25%] text-start border-r-[1px] border-gray-200 p-1">
                            <p className="flex items-center gap-x-3">
                                <label className="font-lato pl-2 text-custom font-semibold text-gray-600">Departure</label>
                                <RiArrowDropDownLine className="text-blue-500 text-2xl" />
                            </p>

                            <input
                                type="date"
                                value={formValues.selectedDate}
                                onChange={(e) => handleDateChange('selectedDate', e.target.value)}
                                className="w-full text-xl font-bold text-[#757575] pl-1 border-none focus:outline-none"
                            />
                        </div>

                        {/* Pickup Time Section */}
                        <div className="w-[20%] text-start border-r-[1px] border-gray-200 p-1">
                            <p className="flex items-center gap-x-3" >
                                <label className="font-lato pl-2 text-custom font-semibold text-gray-600">Pickup-Time</label>
                                <RiArrowDropDownLine className="text-blue-500 text-2xl" />
                            </p>
                            <input
                                type="time"
                                value={formValues.selectedTime}
                                onChange={(e) => handleTimeChange('selectedTime', e.target.value)}
                                className="w-full text-xl font-bold pl-1 border-none focus:outline-none"
                            />
                        </div>

                        {/* Drop Date */}
                        <div className="text-start border-r-[1px] border-gray-200 p-1 col-span-1">
                            <p className="flex items-center gap-x-3">
                                <label className="font-lato pl-2 text-custom font-semibold text-gray-600">Drop Date</label>
                                <RiArrowDropDownLine className="text-blue-500 text-2xl" />
                            </p>
                            <input
                                type="date"
                                value={formValues.dropoffDate}
                                onChange={(e) => handleDateChange('dropoffDate', e.target.value)}
                                className="w-full text-xl font-bold text-[#757575] pl-1 border-none focus:outline-none"
                            />
                        </div>
                        <div className="text-start border-r-[1px] border-gray-200 p-1 col-span-1">
                            <p className="flex items-center gap-x-3">
                                <label className="font-lato pl-2 text-custom font-semibold text-gray-600">Drop-Time</label>
                                <RiArrowDropDownLine className="text-blue-500 text-2xl" />
                            </p>
                            <input
                                type="time"
                                value={formValues.dropoffTime}
                                onChange={(e) => { handleTimeChange("dropoffTime", e.target.value) }}
                                className="w-full text-xl font-bold pl-1 border-none focus:outline-none"
                            />
                        </div>


                    </div>
                    <button
                        onClick={handleSearch}
                        className="absolute  bottom-3 left-1/2 transform -translate-x-1/2 w-[146px] py-1 px-5 rounded-full shadow-lg text-lg text-white"
                        style={{ background: "linear-gradient(90.61deg, #53B2FE 0%, #065AF3 100%)" }}
                    >
                        Search
                    </button>
                </section>
                {/* Small screen ui */}
                <div className="sm:flex xs:flex xsm:flex pb-4 visible flex-col lg:hidden my-4 md:hidden w-[90vw] mx-auto space-y-2 lg:h-[80vh] md:h-[80vh] xsm:h-fit ">
                    {/* From Section */}
                    <div className="relative flex items-center w-full text-start md:border-b-[1px] font-semibold p-1 xsm:bg-[#EDEDED] rounded-md border-[0.8px] border-[#E7E7E7]">
                        <IoLocation className="w-[2rem]" />
                        <div className="w-full">
                            <label className="font-lato pl-2 text-[0.62rem] font-semibold text-[#4A4A4A]">From</label>
                            <select
                                value={formValues.fromLocation}
                                className="font-lato text-[0.8rem] focus:ring-0 focus:outline-none bg-[#EDEDED] placeholder:font-normal font-semibold leading-[20.64px] text-left w-full pl-3 text-[#4A4A4A]"
                                onChange={(e) => handleInputChange("fromLocation", e.target.value)}
                            >
                                <option className="text-[#4A4A4A]" value="" disabled>Select Location</option>
                                <option value="Udaipur" className="text-[#4A4A4A]">Udaipur</option>
                                <option value="Bhopal" className="text-[#4A4A4A]">Bhopal</option>
                                <option value="Indore" className="text-[#4A4A4A]">Indore</option>
                            </select>
                        </div>
                    </div>
                    {/* To Section */}
                    <div className="w-full flex items-center text-start border-[0.8px] border-[#E7E7E7]  xsm:bg-[#EDEDED] rounded-sm">
                        <GrLocationPin className="w-[2rem]" />
                        <div>
                            <label className="font-lato pl-2 text-[0.62rem] font-semibold text-[#4A4A4A]">To</label>
                            <input
                                type="text"
                                placeholder="Select Destination"
                                value={formValues.toLocation}
                                className="font-lato text-[0.8rem] placeholder:text-[0.8rem] text-[#4A4A4A] focus:ring-0 focus:outline-none bg-[#EDEDED] placeholder:font-normal font-semibold leading-[20.64px] text-left w-full pl-3"
                                onChange={(e) => handleInputChange("toLocation", e.target.value)}
                            />
                        </div>
                    </div>

                    <div className="flex w-full grid-cols-2 gap-2">
                        <div className="w-full flex items-center col-span-1 text-start border-[0.8px] border-[#E7E7E7] p-1 xsm:bg-[#EDEDED] rounded-sm">
                            <MdDateRange className="w-[2rem]" />
                            <div className="w-full">
                                <p className="flex items-center gap-x-3">
                                    <label className="font-lato pl-2 text-[0.62rem] font-semibold text-[#4A4A4A]">Pickup Date</label>
                                </p>
                                <input
                                    type="date"
                                    value={formValues.selectedDate}
                                    onChange={(e) => handleDateChange('selectedDate', e.target.value)}
                                    className="w-full text-[0.8rem] font-semibold pl-1 bg-[#EDEDED] border-none focus:outline-none"
                                />
                            </div>
                        </div>
                        <div className="w-full flex items-center col-span-1 text-start border-[0.8px] border-[#E7E7E7] p-1 xsm:bg-[#EDEDED] rounded-sm">
                            <IoIosTimer className="w-[2rem]" />
                            <div className="w-full">
                                <p className="flex items-center gap-x-3">
                                    <label className="font-lato pl-2 text-[0.62rem] font-semibold text-[#4A4A4A]">Pickup Time</label>
                                </p>
                                <input
                                    type="time"
                                    value={formValues.selectedTime}
                                    onChange={(e) => handleTimeChange("selectedTime", e.target.value)}
                                    className="w-full text-[0.8rem] font-bold pl-1 border-none focus:outline-none bg-[#EDEDED]"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="grid grid-cols-2 gap-2 w-full">
                        {/* Drop Date Section */}
                        <div className="w-full flex items-center col-span-1 text-start border-[0.8px] border-[#E7E7E7] p-1 xsm:bg-[#EDEDED] rounded-sm">
                            <MdDateRange className="w-[2rem]" />
                            <div className="w-full">
                                <label className="font-lato pl-2 text-[0.62rem] font-semibold text-[#4A4A4A]">Drop Date</label>
                                <input
                                    type="date"
                                    value={formValues.dropoffDate}
                                    onChange={(e) => handleDateChange('dropoffDate', e.target.value)}
                                    className="w-full text-[0.8rem] font-semibold pl-1 bg-[#EDEDED] border-none focus:outline-none"
                                />
                            </div>
                        </div>

                        {/* Drop Time Section */}
                        <div className="w-full flex items-center col-span-1 text-start border-[0.8px] border-[#E7E7E7] p-1 xsm:bg-[#EDEDED] rounded-sm">
                            <IoIosTimer className="w-[2rem]" />
                            <div className="w-full">
                                <label className="font-lato pl-2 text-[0.62rem] font-semibold text-[#4A4A4A]">Drop Time</label>
                                <input
                                    type="time"
                                    value={formValues.dropoffTime}
                                    onChange={(e) => handleTimeChange("dropoffTime", e.target.value)}
                                    className="w-full text-[0.8rem] font-bold pl-1 bg-[#EDEDED] border-none focus:outline-none"
                                />
                            </div>
                        </div>
                    </div>
                   <button
                        onClick={handleSearch}
                        className=" w-[90vw] py-1 px-5 rounded-full shadow-lg text-lg text-white"
                        style={{ background: "linear-gradient(90.61deg, #53B2FE 0%, #065AF3 100%)" }}
                    >
                        Search
                    </button>
                </div>
            </div>
        </section>
    );
};

export default ShowBookingDetail;
