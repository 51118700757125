import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isAuthenticated: localStorage.getItem("token") ? true : false,
    loading: false,
    user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem('user')) : null,
    token: localStorage.getItem("token") ? JSON.parse(localStorage.getItem('token')) : null,
    error: null,
};

try {
    const userData = localStorage.getItem("user");
    if (userData) {
        initialState.user = JSON.parse(userData);
    }
} catch (error) {
    console.error("Failed to parse user data from localStorage:", error);
} 

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setToken(state, action) {
            state.token = action.payload;
        },
        setUser(state, action) {
            const {token}=action.payload;
            state.user = action.payload;
            state.token=token;
            state.isAuthenticated = true;
        },
        logout(state) {
            state.isAuthenticated = false;
            state.user = null;
            state.token=null;
            state.error=null;
            localStorage.removeItem("token");
            localStorage.removeItem("user");
        },
    },
});

export const { setToken,setUser, logout } = authSlice.actions;
export default authSlice.reducer;
