import React, { useState } from 'react';

const EditProfileModal = ({ isOpen, onClose,formData,setFormData }) => {
    // const [formData, setFormData] = useState({
    //     Name: '',
    //     Birthday: '',
    //     Gender: '',
    //     MaritalStatus: '',
    //     Address: '',
    //     Pincode: '',
    //     State: '',
    // });   

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = (e) => {
        e.preventDefault(); // Prevent default form submission
        onClose(); // Close modal after save
    };


    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white w-full font-lato text-[0.9rem] text-start max-w-lg p-6 rounded-lg shadow-lg">
                <h2 className="text-xl font-semibold mb-4">Edit Profile</h2>
                <form onSubmit={handleSubmit}>
                    <div className="grid grid-cols-2 gap-4 mb-4">
                        {/* Full Name */}
                        <div>
                            <label className="block text-gray-700">Full Name *</label>
                            <input
                                type="text"
                                name="Name"
                                value={formData.Name}
                                onChange={handleChange}
                                className="w-full mt-1 p-2 border border-gray-300 rounded-md"
                            />
                        </div>

                        {/* Birthday */}
                        <div>
                            <label className="block text-gray-700">Birthday</label>
                            <input
                                type="date"
                                name="Birthday"
                                value={formData.Birthday}
                                onChange={handleChange}
                                className="w-full mt-1 p-2 border border-gray-300 rounded-md"
                            />
                        </div>

                        {/* Gender */}
                        <div>
                            <label className="block text-gray-700">Gender</label>
                            <select
                                name="Gender"
                                value={formData.Gender}
                                onChange={handleChange}
                                className="w-full mt-1 p-2 border border-gray-300 rounded-md"
                            >
                                <option value="MALE">MALE</option>
                                <option value="FEMALE">FEMALE</option>
                                <option value="OTHER">OTHER</option>
                            </select>
                        </div>

                        {/* Marital Status */}
                        <div>
                            <label className="block text-gray-700">Marital Status</label>
                            <select
                                name="MaritalStatus"
                                value={formData.MaritalStatus}
                                onChange={handleChange}
                                className="w-full mt-1 p-2 border border-gray-300 rounded-md"
                            >
                                <option value="SINGLE">SINGLE</option>
                                <option value="MARRIED">MARRIED</option>
                            </select>
                        </div>

                        {/* Address */}
                        <div>
                            <label className="block text-gray-700">Your Address</label>
                            <input
                                type="text"
                                name="Address"
                                value={formData.Address}
                                onChange={handleChange}
                                className="w-full mt-1 p-2 border border-gray-300 rounded-md"
                            />
                        </div>

                        {/* Pincode */}
                        <div>
                            <label className="block text-gray-700">Pincode</label>
                            <input
                                type="text"
                                name="Pincode"
                                value={formData.Pincode}
                                onChange={handleChange}
                                className="w-full mt-1 p-2 border border-gray-300 rounded-md"
                            />
                        </div>

                        {/* State */}
                        <div>
                            <label className="block text-gray-700">State</label>
                            <select
                                name="State"
                                value={formData.State}
                                onChange={handleChange}
                                className="w-full mt-1 p-2 border border-gray-300 rounded-md"
                            >
                                <option value="Haryana">Haryana</option>
                                <option value="Punjab">Punjab</option>
                                <option value="Delhi">Delhi</option>
                                <option value="Other">Other</option>
                            </select>
                        </div>
                    </div>

                    {/* Buttons */}
                    <div className="flex justify-between items-center mt-4">
                        <button
                            type="button"
                            className="px-4 py-2 text-gray-500"
                            onClick={onClose}
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="px-4 py-2 bg-blue-600 text-white rounded-md"
                        >
                            Save
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EditProfileModal;
