import React, { useEffect, useState } from 'react';
import { OnboardNewTTVehicle } from '../../Services/AddVehicleDetails';
import { tourandPacakges } from '../../Services/adminAPI';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    TablePagination, Paper, Typography, Button, Box
} from '@mui/material';
import TourPackageModal from '../Models/VehicleTTModal';
import PackageViewModal from './PackageModal';
import SEOHelmet from '../../Components/common/SeoHelmet';

const OnboardedTTVehicleList = () => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [packeges, setPackeges] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [openTourPackage, setOpenTourPackage] = useState(false);
    const [selectedPackage, setSelectedPackage] = useState(null);

    const handleViewDetails = (pkg) => {
        setSelectedPackage(pkg);
        setOpenTourPackage(true);
    };

    const handleClose = () => {
        setOpenTourPackage(false);
        setSelectedPackage(null);
    };

    useEffect(() => {
        const fetchTourPackages = async () => {
            try {
                const response = await tourandPacakges();
                setPackeges(response?.data?.tourPackages);
            } catch (error) {
                console.error("Error fetching vehicle list:", error);
            }
        };
        fetchTourPackages();
    }, []);

    const handleAddVehicle = async (vehicleData) => {
        try {
            const response = await OnboardNewTTVehicle(vehicleData);
            if (response.status === 201) {

            }
        } catch (error) {
            console.error("Error adding vehicle:", error);
        }
    };

    return (
        <Paper sx={{ width: '100%', maxHeight: "72vh" }}>
             <SEOHelmet title="Onboarded Tour&Travel Vehicle | TSJ Car Rentals "/>

            <Box p={2} sx={{
                display: "flex",
                justifyContent: "space-between",
            }}>
                <Typography variant="h5" sx={{ fontWeight: "600", float: "left" }}>All Added Tour And Packages</Typography>
                <Button variant='contained' onClick={() => setModalOpen(true)}>Add New Tour And Travel Package</Button>
            </Box>
            <TableContainer component={Paper} sx={{
                // height: '400px',
                overflowY: 'scroll',
            }}>
                <Table stickyHeader aria-label="vehicle table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ backgroundColor: "primary.main", color: "white" }}>Package Name</TableCell>
                            <TableCell sx={{ backgroundColor: "primary.main", color: "white" }}>Source</TableCell>
                            <TableCell sx={{ backgroundColor: "primary.main", color: "white" }}>Destination</TableCell>
                            <TableCell sx={{ backgroundColor: "primary.main", color: "white" }}>Package Price (₹) </TableCell>
                            <TableCell sx={{ backgroundColor: "primary.main", color: "white" }}>Transportation</TableCell>
                            <TableCell sx={{ backgroundColor: "primary.main", color: "white" }}>Accommodation Type</TableCell>
                            <TableCell sx={{ backgroundColor: "primary.main", color: "white" }}>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {packeges?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item) => (
                            <TableRow key={item._id} hover>
                                <TableCell sx={{ color: "gray" }}>{item.packageName}</TableCell>
                                <TableCell sx={{ color: "gray" }}>{item.source}</TableCell>
                                <TableCell sx={{ color: "gray" }}>{item.destination}</TableCell>
                                <TableCell sx={{ color: "gray" }}>{item.packagePrice} (₹)</TableCell>
                                <TableCell sx={{ color: "gray" }}>{`₹${item.transportation}`}</TableCell>
                                <TableCell sx={{ color: "gray" }}>{item.accommodationType}</TableCell>
                                <TableCell sx={{ color: "gray" }} onClick={() => handleViewDetails(item)}>View</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={packeges.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={(e, newPage) => setPage(newPage)}
                onRowsPerPageChange={(e) => setRowsPerPage(parseInt(e.target.value, 10))}
            />

            <TourPackageModal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                onSubmit={handleAddVehicle}
            />

            <PackageViewModal
                open={openTourPackage}
                onClose={() => handleClose()}
                selectedPackage={selectedPackage}
            />
        </Paper>
    );
};

export default OnboardedTTVehicleList;
