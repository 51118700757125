import React from 'react'
import RentalBookingView from '../Layout/tables/AdminBookings/RentalBookingDetails'
import {
    managerApproveRejectRental,
} from "../Services/adminAPI";
const ManagerRentalBookingApproval = () => {
    return (
        <RentalBookingView
            approveRejectRental={managerApproveRejectRental}
        />
    )
}

export default ManagerRentalBookingApproval