import React, { useState } from 'react';

const CabList = () => {
  const [cabCount, setCabCount] = useState(4);
  const routeInfo = {
    from: "Mumbai",
    to: "Pune",
    distance: 149,
    duration: "Approx 3 hr(s)"
  };

  return (
    <div className=" bg-blue-50 p-4 md:block hidden rounded-md w-full px-4 md:mt-0 lg:mt-1 xsm:mt-[8rem] h-[52p]"
      style={{
        background: 'linear-gradient(269.9deg, rgba(58, 123, 213, 0.15) 50%, rgba(0, 210, 255, 0.15) 100%)'
      }}
    >
      <div

        className="flex items-center w-[65vw] mx-auto space-x-2 text-sm text-gray-700 font-lato">
        <span>|</span>
        {/* <span>
          Showing {cabCount} out of {cabCount} cabs from {routeInfo.from} to {routeInfo.to}
          (Distance for selected route is {routeInfo.distance}kms | {routeInfo.duration})
        </span> */}
      </div>
    </div>
  );
};

export default CabList;
