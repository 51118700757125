import React, { useEffect, useState } from "react";
import { allBlogList } from "../../../Services/common";
import { deletBlog } from "../../../Services/adminAPI";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Paper,
    Typography,
    Box,
    Button,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@mui/material";
import { formatDate } from "../../../utils/FormateValue";
import SEOHelmet from "../../../Components/common/SeoHelmet";
import EditBlog from "../../../Components/Core/Dashboard/Blog/EditBlog";
import CloseIcon from '@mui/icons-material/Close';

const AllBlogList = () => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [blogList, setBlogList] = useState([]);
    const [selectedBlogId, setSelectedBlogId] = useState(null);
    const [editBlogOpen, setEditBlogOpen] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

    const fetchAllBlogs = async () => {
        try {
            const response = await allBlogList();
            if (response.status === 200) {
                setBlogList(response?.data?.blogs);
            }
        } catch (error) {
            console.error("Error fetching blogs:", error);
        }
    };

    useEffect(() => {
        fetchAllBlogs();
    }, []);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleDelete = async () => {
        try {
            const response = await deletBlog(selectedBlogId);
            if (response.status === 200) {
                fetchAllBlogs();
                setDeleteDialogOpen(false);
            }
        } catch (error) {
            console.error("Error deleting blog:", error);
        }
    };

    const handleEdit = (blogId) => {
        setSelectedBlogId(blogId);
        setEditBlogOpen(true);
    };

    const handleCloseEditBlog = () => {
        setEditBlogOpen(false);
    };

    return (
        <>
            <SEOHelmet title="All Blogs | TSJ Car Rentals" />

            {!editBlogOpen ? (
                <Paper sx={{ width: "100%", overflow: "hidden", marginTop: 4 }}>
                    <Box p={2} sx={{ textAlign: "start" }}>
                        <Typography variant="h5" sx={{ fontWeight: "600", float: "left" }}>
                            All Uploaded Blogs
                        </Typography>
                    </Box>
                    <TableContainer
                        component={Paper}
                        sx={{
                            height: "400px",
                            overflowY: "scroll",
                        }}
                    >
                        <Table stickyHeader aria-label="blog table">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ backgroundColor: "primary.main", color: "white" }}>Title</TableCell>
                                    <TableCell sx={{ backgroundColor: "primary.main", color: "white" }}>Image</TableCell>
                                    <TableCell sx={{ backgroundColor: "primary.main", color: "white" }}>
                                        Uploaded On
                                    </TableCell>
                                    <TableCell sx={{ backgroundColor: "primary.main", color: "white" }}>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {blogList
                                    ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((item) => (
                                        <TableRow hover key={item._id} sx={{ cursor: "pointer" }}>
                                            <TableCell sx={{ color: "gray" }}>{item.title}</TableCell>
                                            <TableCell sx={{ color: "gray" }}>
                                                <img src={item.coverImage} alt={item.title} width={50} height={50} />
                                            </TableCell>
                                            <TableCell sx={{ color: "gray" }}>{formatDate(item.createdAt)}</TableCell>
                                            <TableCell
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "space-around",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <span
                                                    style={{ cursor: "pointer", color: "red" }}
                                                    onClick={() => {
                                                        setSelectedBlogId(item._id);
                                                        setDeleteDialogOpen(true);
                                                    }}
                                                >
                                                    Delete
                                                </span>

                                                <span
                                                    style={{ cursor: "pointer", color: "blue" }}
                                                    onClick={() => handleEdit(item._id)}
                                                >
                                                    Edit
                                                </span>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={blogList?.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            ) : (
                <Box sx={{ width: "100%", position: "relative" }}>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={handleCloseEditBlog}
                        aria-label="close"
                        sx={{
                            position: 'absolute',
                            right: 16,
                            top: 16,
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <EditBlog
                        blogId={selectedBlogId}
                        onSuccess={() => {
                            setEditBlogOpen(false);
                            fetchAllBlogs();
                        }}
                    />
                </Box>
            )}

            <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>Are you sure you want to delete this blog?</DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteDialogOpen(false)} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleDelete} color="error">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default AllBlogList;
