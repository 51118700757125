import React, { useState, useEffect } from 'react';
import { Checkbox, TextField, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { vehicleTypes } from '../../../data/HomePageData';
import { useSelector } from 'react-redux';

const TripDetailsForm = () => {
  const { user } = useSelector((state) => state.auth)
  const [formData, setFormData] = useState({
    name: user?.name,
    email: user?.email,
    contactNumber: user?.mobile, // Added for contact number
  });

  const location = useLocation();

  const [urlData, setUrlData] = useState({
    vehicleTypes: "",
    fromLocation: "",
    toLocation: "",
    pickupDate: "",
    pickupTime: ""
  });

  useEffect(() => {
    const pathSegments = location.pathname.split("/");
    const storedFormData = localStorage.getItem('formData');
    const queryParams = new URLSearchParams(location.search);
    // const vehicleType = queryParams.get("vehicleType");
    const from = queryParams.get("pickupLocation");
    const to = queryParams.get("dropLocation");
    const pickupDate = queryParams.get("pickUpDate");
    const pickupTime = queryParams.get("pickUpTime");
    const dropoffDate = queryParams.get("dropoffDate");
    const dropoffTime = queryParams.get("dropoffTime");
    const fuelType = queryParams.get("fuelType");
    const vehicleModel = queryParams.get("vehicleModel");
    setUrlData({
      fromLocation: from,
      toLocation: to
    })

  }, [location.search]);



  return (
    <div
      style={{ boxShadow: "0px 4px 8px 0px #8A6B7614" }}
      className="font-sans text-[0.8rem]">
      {/* Sign Up / Login */}

      <div className='border rounded-lg text-start'>
        <div className="p-4">
          <h2 className="font-bold text-lg mb-6 text-black">Trip details</h2>
          <div className="space-y-6">
            <div>
              <TextField
                label="Pick-up Address"
                value={urlData?.fromLocation?.toUpperCase()}
                helperText="This will help our driver reach you on time"
                fullWidth
                placeholder="Enter exact pick-up address/nearby location / building name"
                InputProps={{
                  style: { fontWeight: 'bold' }, // Make the input text bold
                }}
              />
            </div>
            <div>
              <TextField
                label="Drop-off Address (Optional)"
                value={urlData?.toLocation?.toUpperCase()}
                helperText="This will help you avoid any extra charges after the trip"
                fullWidth
                placeholder="Enter drop address to know the exact fare & avoid extra charges after the trip"
                InputProps={{
                  style: { fontWeight: 'bold' }, // Make the input text bold
                }}
              />
            </div>
          </div>
        </div>

        {/* Traveller Information */}
        <div className="p-4">
          <h2 className="font-bold text-lg mb-6">Confirm Traveller information</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
            <TextField
              label="Name"
              value={formData.name}
              fullWidth
              placeholder="Enter your full name"
              onChange={(e) => setFormData((prev) => ({ ...prev, name: e.target.value }))}
              InputProps={{
                style: { fontWeight: 'bold' },
              }}
            />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <TextField
              label="Email Id"
              value={formData.email}
              fullWidth
              placeholder="Enter Email ID"
              onChange={(e) => setFormData((prev) => ({ ...prev, email: e.target.value }))} 
              InputProps={{
                style: { fontWeight: 'bold' },
              }}
            />
            <div className="flex items-center">
              <span className="text-gray-500">+91</span>
              <TextField
                label="Contact Number"
                value={formData.contactNumber?.slice(2) || ""} 
                fullWidth
                placeholder="Enter 10 digit mobile number"
                className="ml-4"
                onChange={(e) => setFormData((prev) => ({ ...prev, contactNumber: e.target.value }))} 
                InputProps={{
                  style: { fontWeight: 'bold' }, 
                }}
              />
            </div>
          </div>
        </div>

        {/* Terms and Pickup */}
        <div className="flex items-start space-x-4">
          <p className="text-gray-700 font-bold px-4">
            By proceeding to book, I Agree to MakeMyTrip's{' '}
            <a href="#" className="text-blue-600 hover:underline">Privacy Policy</a>,{' '}
            <a href="#" className="text-blue-600 hover:underline">User Agreement</a>, and{' '}
            <a href="#" className="text-blue-600 hover:underline">Terms of Service</a>.
          </p>
        </div>

        <div className="mt-4">
          <Checkbox defaultChecked color="primary" />
          <span className="text-gray-700">Use pickup location as billing address</span>
        </div>
      </div>
    </div>
  );
};

export default TripDetailsForm;
