import React, { useState, useEffect, useCallback } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
const CarDetails = ({ vehicle }) => {

    const { user, token } = useSelector((state) => state.auth)
    const [rateType, setRateType] = useState('')
    const location = useLocation();
    const vehicleTypes = location.pathname.split('/')[2];
    const navigate = useNavigate();
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        let rateType = queryParams.get("rateType");
        setRateType(rateType)
        console.log("Types of Data of Rate", rateType)
    }, [location.search])
    const [queryParams, setQueryParams] = useState(null);



    useEffect(() => {
        // Parse query parameters when the component mounts
        let queryParams = new URLSearchParams(location.search);

        const from = queryParams.get("from");
        const to = queryParams.get("to");
        const pickupDate = queryParams.get("pickUpDate");
        const pickupTime = queryParams.get("pickUpTime");
        const dropoffDate = queryParams.get("dropoffDate");
        const dropoffTime = queryParams.get("dropoffTime");
        const fuelType = queryParams.get("fuelType");
        const vehicleModel = queryParams.get("vehicleModel");
        const selectedrateType = queryParams.get("rateType")
        setRateType(selectedrateType)

        setQueryParams(queryParams.toString());
    }, [location.search]);

    const handlebooknavigate = (selectedvehicleData) => {
        localStorage.setItem('selectedVehicle', JSON.stringify(selectedvehicleData));
        localStorage.setItem("currentPath", '/book-details/');
        if (!token && !user) {
            navigate("/signup")
        }
        else if (!user?.isDetailsFilled) {
            navigate(`/book-details/${vehicleTypes}?${queryParams}`);
        } else {
            navigate(`/review-booking/${vehicleTypes}?${queryParams}`);

        }
    };
    return (
        vehicle ? (
            <motion.section
                key={vehicle._id}
                className="grid grid-cols-12 w-full font-lato max-w-[90vw] place-content-center  xsm:text-[0.8rem] sm:text-[0.9rem] sm:mx-auto   border-[#BABABA] border-[1px] rounded-md min-h-[200px] max-h-auto p-4"
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5 }}
            >
                <div className="md:col-span-3 xsm:col-span-12">
                    <div className="grid place-content-center rounded xsm:p-1 md:p-3 md:w-full ms:h-[100px] xsm:w-full">
                        <img src={vehicle.img} className="w-full h-full rounded" alt="Vehicle" />
                    </div>
                </div>
                <div className=" xsm:col-span-9 md:col-span-7  flex flex-col space-y-1 max-w-[532px] font-lato">
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.3, delay: 0.2 }}
                        className="flex gap-1 items-start"
                    >
                        <span className="font-bold text-[1rem] md:w-[10rem] lg:w-[12rem]">
                            {vehicle.vehicleModel || "Car Model"}
                        </span>
                        <span
                            style={{ background: 'linear-gradient(74.29deg, #26BD99 0%, #219393 100%)' }}
                            className="w-[3rem] h-[1rem] rounded text-[0.8rem]"
                        >
                            {vehicle.rating}/5
                        </span>
                    </motion.div>
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.3, delay: 0.4 }}
                        className="flex flex-col text-[0.8rem]  justify-start text-start gap-1"
                    >
                        <span className="flex w-full xsm:space-x-3 justify-start">
                            <span className="w-[25%] font-bold">Fuel Type</span>
                            <span className="md:text-[0.9rem]">{vehicle?.fuelType || "CNG"}</span>
                        </span>
                        <span className="flex w-full xsm:space-x-3 text-start justify-start">
                            <span className="w-[25%] font-bold">Vehicle Type</span>
                            <span className="md:text-[0.9rem]">{vehicle.vehicleType}</span>
                        </span>
                        <span className="flex w-full xsm:space-x-3">
                            <span className="w-[25%] font-bold">Seats</span>
                            <span className="md:text-[0.9rem]">{vehicle?.seats}</span>
                        </span>

                        <div className="md:flex hidden  w-full xsm:space-x-3">
                            <span className="w-[25%] font-bold">Amenities</span>
                            <div className="font-lato flex flex-wrap md:flex-row xsm:flex-col md:w-[50%] lg:w-[75%] xsm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 md:text-[0.9rem]">
                                {vehicle.amenities && vehicle.amenities.length > 0 ? (
                                    vehicle.amenities.map((amenity, index) => (
                                        <span key={index} className="w-fit pr-1 md:text-[0.9rem]">
                                            {amenity}
                                            {index < vehicle.amenities.length - 1 && ", "}
                                        </span>
                                    ))
                                ) : (
                                    <li>No amenities available.</li>
                                )}
                            </div>
                        </div>
                        <div className="flex w-full xsm:space-x-3">
                            <span className="w-[25%] font-bold">Extra Charges</span>
                            <span className="md:text-[0.9rem] xsm:w-[75%] ">
                                {rateType === "ratePerDay"
                                    ? `₹${vehicle.extraHoursRate}/Hrs will be charged for every extra Hours`
                                    : `Limit: ${vehicle.limitPer24Hrs} km/24Hrs and ₹${vehicle.extraKmRate}/km will be charged for every extra km.`}
                            </span>
                        </div>
                        <motion.div
                            className="flex flex-col w-full space-y-1 text-start"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 0.3, delay: 0.6 }}
                        >

                            <div className="flex w-full xsm:space-x-3">
                                <span className="  w-[25%] font-bold">Special Notes</span>

                                <span className="text-green-700 md:text-[0.9rem] font-semibold font-lato text-wrap xsm:w-[12rem] md:w-[75%]"><span>{vehicle?.cancellationRule || vehicle?.description}</span>
                                </span>

                            </div>

                        </motion.div>
                    </motion.div>
                </div>
                <div className="  md:col-span-2 xsm:col-span-3 xsm:mt-3 md:mt-0 text-end h-full">

                    <div className="flex flex-col xsm:justify-start sm:justify-center md:justify-center items-end h-full">
                        <div className="flex flex-col-reverse gap-2">
                            <span className="md:text-[1.2rem] xsm:text-[1.1rem] font-bold"> ₹{rateType === "ratePerDay" ? vehicle?.ratePerDay : vehicle?.ratePer24Hrs}</span>
                        </div>
                        <div className="flex flex-col gap-2">
                            <Link to={"/trip-details"}>
                            </Link>
                            <button
                                onClick={() => handlebooknavigate(vehicle)}
                                type="submit"
                                className="bg-gradient-to-r  from-blue-400 to-blue-600 text-white sm:text-[1.1rem] xsm:text-[0.rem] xsm:px-2 sm:px-4 xsm:py-2 md:text-[0.9rem]  md:px-6 sm:py-2 rounded-full"
                            >
                                Book Now
                            </button>
                        </div>
                    </div>
                </div>
            </motion.section>
        ) : (
            <div>No vehicle data available.</div>
        )
    );
};

export default CarDetails;
