import React, { useState } from "react";
import toast from "react-hot-toast";
import {
  Box,
  TextField,
  Button,
  Typography,
  Grid2,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  CircularProgress,
} from "@mui/material";
import { vendorRequest } from "../Services/common";
import Navbar from "../Components/common/Navbar";
import SEOHelmet from "../Components/common/SeoHelmet";

const VendorOnboardingForm = () => {
  const [fileErrors, setFileErrors] = useState({});
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    address: {
      street: "",
      city: "",
      state: "",
      postalCode: "",
      country: "",
    },
    aadharCardNumber: "",
    dlNumber: "",
    dlExpiry: "",
    dlType: "",
    dlCode: "",
    vehicleType: "",
    registrationNumber: "",
    seatingCapacity: "",
    vehicleModel: "",
    fuelType: "",
    vehicleNumber: "",
    transmission: "",
    amenities: "",
  });

  const [files, setFiles] = useState({
    aadharCardFrontImg: null,
    aadharCardBackImg: null,
    dlImg: null,
    pollutionCertificateImg: null,
    odoMeterImg: null,
    vehicleImg: null,
    insuranceImg: null,
    rcImage: null,
  });

  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name.startsWith("address.")) {
      const field = name.split(".")[1];
      setFormData((prevFormData) => ({
        ...prevFormData,
        address: {
          ...prevFormData.address,
          [field]: value,
        },
      }));
    } else {
      setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    }
  };

 

  const handleFileChange = (e) => {
    const { name, files: selectedFiles } = e.target;
    const file = selectedFiles[0];
    setFileErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    if (file) {
      if (file.size > 300 * 1024) {
        toast.error(`${file.name} exceeds the 300KB size limit. Please upload a smaller file.`);
        setFileErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "File size should be less than 300KB",
        }));
      } else {
        setFiles((prevFiles) => ({ ...prevFiles, [name]: file }));
      }
    }
  };

  const validateFilesBeforeSubmit = () => {
    let isValid = true;
    const newErrors = {};

    Object.entries(files).forEach(([key, file]) => {
      if (file && file.size > 300 * 1024) {
        isValid = false;
        newErrors[key] = "File size should be less than 300KB";
      }
    });

    setFileErrors(newErrors);
    return isValid;
  };

  const resetForm = () => {
    setFormData({
      name: "",
      email: "",
      mobile: "",
      address: {
        street: "",
        city: "",
        state: "",
        postalCode: "",
        country: "",
      },
      aadharCardNumber: "",
      dlNumber: "",
      dlExpiry: "",
      dlType: "",
      dlCode: "",
      vehicleType: "",
      registrationNumber: "",
      seatingCapacity: "",
      vehicleModel: "",
      fuelType: "",
      vehicleNumber: "",
      transmission: "",
      amenities: "",
    });
    setFiles({
      aadharCardFrontImg: null,
      aadharCardBackImg: null,
      dlImg: null,
      pollutionCertificateImg: null,
      odoMeterImg: null,
      vehicleImg: null,
      insuranceImg: null,
      rcImage: null,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!validateFilesBeforeSubmit()) {
      toast.error("Please fix the errors before submitting.");
      setLoading(false);
      return;
    }

    try {
      const data = new FormData();
      Object.entries(formData).forEach(([key, value]) => {
        if (typeof value === "object" && value !== null) {
          Object.entries(value).forEach(([nestedKey, nestedValue]) => {
            data.append(`${key}.${nestedKey}`, nestedValue);
          });
        } else {
          data.append(key, value);
        }
      });
      Object.entries(files).forEach(([key, file]) => {
        if (file) data.append(key, file);
      });

      console.log("Submitted Data:", Object.fromEntries(data.entries()));

      const response = await vendorRequest(data);
      if (response.status === 201) {
        toast.success("Vendor onboarding request submitted successfully!");
        resetForm();
      }

      console.log("Response:", response.data);
    } catch (error) {
      console.error("Error submitting the form:", error);
      toast.error("Failed to submit the form. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <SEOHelmet title="Become Host | TSJ Car Rentals" />

      <Navbar />
      <Box sx={{ p: 4, maxWidth: 800, mx: "auto" }}>

        <Typography variant="h4" mb={3} textAlign="center">
          Vendor Onboarding Request
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid2 container spacing={2}>
            {Object.entries(formData).map(([key, value]) => {
              const formattedLabel = key.includes("dl")
                ? key.replace(/dl/gi, "DL")
                : key.replace(/([A-Z])/g, " $1").trim().replace(/^./, (str) => str.toUpperCase());

              if (key === "transmission") {
                return (
                  <Grid2 item size={{xs:12, sm:6}}  key={key}>
                    <FormControl fullWidth>
                      <InputLabel id="transmission-label">Transmission</InputLabel>
                      <Select
                        labelId="transmission-label"
                        name="transmission"
                        value={value}
                        onChange={handleInputChange}
                        required
                      >
                        <MenuItem value="Manual Transmission">Manual Transmission</MenuItem>
                        <MenuItem value="Automatic Transmission">Automatic Transmission</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid2>
                );
              }
              if (key !== "address" && typeof value !== "object") {
                return (
                  <Grid2 item size={{xs:12, sm:6}} key={key}>
                    <TextField
                      label={formattedLabel}
                      name={key}
                      fullWidth
                      value={value}
                      onChange={handleInputChange}
                      // required={key === "name" || key === "mobile"}
                      required

                    />
                  </Grid2>
                );
              }
              return null;
            })}
            {["street", "city", "state", "postalCode", "country"].map((field) => (
              <Grid2 item size={{xs:12, sm:6}} key={field}>
                <TextField
                  label={field.charAt(0).toUpperCase() + field.slice(1)}
                  name={`address.${field}`}
                  fullWidth
                  value={formData.address[field]}
                  onChange={handleInputChange}
                  required
                />
              </Grid2>
            ))}
            {/* Document Uploads */}
            <Grid2 item size={12}>
              <Typography variant="h6">Upload Documents</Typography>
              <p style={{ color: "red" }}>Notice:- All image size should be of less than 300kb </p>

            </Grid2>
            {Object.keys(files).map((key) => (
              <Grid2 item size={{xs:12, sm:6}} key={key}>
                <Button variant="outlined" component="label" fullWidth>
                  {key.includes("dl")
                    ? key.replace(/dl/gi, "DL")
                    : key.replace(/([A-Z])/g, " $1").trim()}
                  <input
                    type="file"
                    name={key}
                    hidden
                    onChange={handleFileChange}
                  />
                </Button>
                {files[key] && (
                  <Typography variant="body2" mt={1} color="textSecondary">
                    Selected: {files[key].name}
                  </Typography>
                )}
                {fileErrors[key] && (
                  <Typography variant="body2" mt={1} color="error">
                    {fileErrors[key]}
                  </Typography>
                )}
              </Grid2>
            ))}
            {/* Submit Button */}
            <Grid2 item size={12}>
              <Button type="submit" variant="contained" fullWidth disabled={loading}>
                {loading ? (
                  <CircularProgress size={24} sx={{ color: "white" }} />
                ) : (
                  "Submit Request"
                )}
              </Button>
            </Grid2>
          </Grid2>
        </form>
      </Box>
    </>

  );
};

export default VendorOnboardingForm;
