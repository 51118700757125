import React, { useEffect, useState } from "react";
import { RiArrowDropDownLine } from "react-icons/ri";
import { useNavigate, useLocation } from "react-router-dom";
import { IoLocation } from "react-icons/io5"
import { GrLocationPin } from "react-icons/gr";
import { MdDateRange } from "react-icons/md";
import { IoIosTimer } from "react-icons/io";
import toast from "react-hot-toast";

const getTomorrowDate = (delay) => {
    const date = new Date();
    date.setDate(date.getDate() + 1);
    return date.toISOString().split("T")[0];
};

const getDropDate = (delay) => {
    const date = new Date();
    date.setDate(date.getDate() + 3);
    return date.toISOString().split("T")[0];
};

// Utility function to parse and format date for display
const formatDateDisplay = (date) => {
    return new Date(date).toLocaleDateString("en-GB", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
        timeZone: "UTC",
    });
};



const getCurrentTime = () => {
    const now = new Date();
    return now.toISOString().split("T")[1].slice(0, 5); // Returns "HH:MM"
};



const BookingDate = ({ setSelectedVehicleType, selectedVehicleType, servicesType }) => {
    const [timeAlertModel, setTimeAlertModel] = useState(null);
    const [alerModel, setAlertModel] = useState(null);
    const [message, setMessage] = useState(null);

    const location = useLocation();
    const navigate = useNavigate();

    const [bookingInfo, setBookingInfo] = useState({
        selectedDate: '',
        selectedTime: "10:00",
        fromLocation: "",
        toLocation: "",
        dropoffDate: '',
        dropoffTime: getCurrentTime()
    });

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);

        setBookingInfo((prevState) => ({
            ...prevState,
            fromLocation: queryParams.get("pickupLocation") || "",
            toLocation: queryParams.get("dropLocation") || "",
            selectedDate: queryParams.get("pickupDate") || "",
            selectedTime: queryParams.get("pickupTime") || "10:00",
            dropoffDate: queryParams.get("dropoffDate") || "",
            dropoffTime: queryParams.get("dropoffTime") || getCurrentTime,
        }));
    }, [location]);

    const updateQueryParam = (updates) => {
        const searchParams = new URLSearchParams(location.search);
        Object.keys(updates).forEach((key) => {
            if (updates[key]) {
                searchParams.set(key, updates[key]);
            }
        });
        navigate(`/Trip/${selectedVehicleType.serviceType.replace(/\s+/g, "")}?${searchParams.toString()}`);
    };

    const onClose = () => {
        setAlertModel(null);
        setMessage(null);
    }
    const handleCloseTime = () => {
        setTimeAlertModel(null);
    }

    const handlefillModel = (data) => {
        setAlertModel(data)
        setMessage(data)

    }

    const handleSearch = () => {
        if (!bookingInfo.fromLocation) {
            handlefillModel("Please fill the source location.");
            return;
        } else if (!bookingInfo.toLocation) {
            handlefillModel("Please fill the destination location.");
            return;
        }

        else if (!bookingInfo.selectedDate) {
            handlefillModel("Please select the PickUp date.");
            return;
        } else if (!bookingInfo.selectedTime) {
            handlefillModel("Please select the PickUp Time time.");
            return;
        }
        else if (!bookingInfo.dropoffDate) {
            handlefillModel("Please select the drop-off date.");
            return;
        }
        else if (new Date(bookingInfo.selectedDate) <= new Date(new Date().setDate(new Date().getDate() - 1))) {
            handlefillModel("Pickup date cannot be Back date.");
            return;
        }
        else if (new Date(bookingInfo.selectedDate) > new Date(bookingInfo.dropoffDate)) {
            handlefillModel("Pickup date must be less than or equal to the drop-off date.");
            return;
        }
        else if (!bookingInfo.dropoffTime) {
            handlefillModel("Please select the drop-off time.");
            return;
        }



        const payload = {
            pickupLocation: bookingInfo.fromLocation,
            dropLocation: bookingInfo.toLocation,
            pickupDate: bookingInfo.selectedDate,
            pickupTime: bookingInfo.selectedTime,
            dropoffDate: bookingInfo.dropoffDate,
            dropoffTime: bookingInfo.dropoffTime,
        };

        localStorage.setItem("bookingPayload", JSON.stringify(payload));
        const pickupDateTime = new Date(`${bookingInfo?.selectedDate}T${bookingInfo?.selectedTime}`);
        const currentDateTime = new Date();
        const fourHoursLater = new Date(currentDateTime.getTime() + 4 * 60 * 60 * 1000);
        console.log("fourHoursLater time", fourHoursLater, pickupDateTime)
        console.log(pickupDateTime < fourHoursLater)
        if (pickupDateTime < fourHoursLater) {
            console.log("inside time")
            setTimeAlertModel('Pickup date and time must be at least 4 hours later than the current time')
            return;
        }

        updateQueryParam(payload);
    };

    const handleInputChange = (field, value) => {
        setBookingInfo((prevState) => ({
            ...prevState,
            [field]: value,
        }));
    };

    const handleDateChange = (field, value) => {
        setBookingInfo((prevState) => ({
            ...prevState,
            [field]: value,
        }));
    };

    const handleTimeChange = (field, value) => {
        setBookingInfo((prevState) => ({
            ...prevState,
            [field]: value

        }))
    }


    return (
        <section className="w-[100%] md:h-full lg:h-full ">
            <div className="w-full bg-[#FFFFFF]  relative h-full border-[1px] flex flex-col space-y-2 lg:shadow-lg md:shadow-lg lg:rounded-md md:rounded-md">

                <div className={`w-full md:border-b-[3px] font-bold md:border-[#383838] lg:border-b-[3px]  lg:border-[#383838] h-[5rem] relative px-5 py-1 bg-white rounded-t-md grid grid-cols-3 lg:grid-cols-5 md:grid-cols-5 place-content-center  sm:grid `}>
                    {servicesType?.map((vehicle, index) => (
                        <div
                            key={index}
                            className={`flex flex-col h-full p-1 rounded-sm gap-2 justify-center place-items-center font-lato cursor-pointer 
                    ${selectedVehicleType._id === vehicle._id ? "bg-[#008CFF] shadow-md " : "bg-transparent"}`}
                            onClick={() => setSelectedVehicleType(vehicle)}
                        >
                            <div className="grid place-content-center">
                                <div className="w-[30px] h-[30px] mx-auto">
                                    <img src={`   ${selectedVehicleType._id !== vehicle._id ? vehicle.img : vehicle.Wimg} `} alt={vehicle.name} />
                                </div>
                                <p className={`text-[14px] font-lato ${selectedVehicleType._id === vehicle._id ? "text-[#FFF] font-bold" : " text-[#4A4A4A]"}`}>
                                    {vehicle.serviceType}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>


                <section className="sm:hidden xsm:hidden xs:hidden lg:grid md:grid w-[95%] mx-auto border-[0.8px] border-[#E7E7E7] rounded-lg h-[85px] bg-white grid-cols-6 gap-1 relative">
                    {/* From Location */}
                    <div className="relative text-start border-r-[1px] font-semibold border-gray-200 p-1 col-span-1">
                        <label className="font-lato pl-2 text-custom font-semibold text-black">From</label>
                        <select
                            value={bookingInfo.fromLocation}
                            className="font-lato text-[1rem] focus:ring-0 placeholder:fo focus:outline-none font-black leading-[25.81px] text-left w-full pl-3"
                            onChange={(e) => handleInputChange("fromLocation", e.target.value)}
                        >
                            <option value="" disabled>Select Location</option>
                            <option value="Udaipur">Udaipur</option>
                            <option value="Bhopal">Bhopal</option>
                            <option value="Indore">Indore</option>
                        </select>
                    </div>
                    <div className="text-start border-r-[1px] border-gray-200 p-1 col-span-1">
                        <label className="font-lato pl-2 text-custom font-semibold text-gray-600">To</label>
                        <input
                            type="text"
                            placeholder="Destination"
                            value={bookingInfo.toLocation}
                            className="font-lato text-[21.73px] focus:ring-0 focus:outline-none placeholder:font-semibold font-black leading-[25.81px] text-left w-full pl-3"
                            onChange={(e) => handleInputChange("toLocation", e.target.value)}
                        />
                    </div>

                    {/* Pickup Date */}
                    <div className="text-start border-r-[1px] border-gray-200 p-1 col-span-1">
                        <p className="flex items-center gap-x-3">
                            <label className="font-lato pl-2 text-custom font-semibold text-black">Pickup Date</label>
                            <RiArrowDropDownLine className="text-blue-500 text-2xl" />
                        </p>
                        <input
                            type="date"
                            value={bookingInfo.selectedDate}
                            onChange={(e) => handleDateChange("selectedDate", e.target.value)}
                            className="w-full text-xl font-bold text-[#757575] pl-1 border-none focus:outline-none"
                        />
                    </div>
                    <div className="text-start border-r-[1px] border-gray-200 p-1 col-span-1">
                        <p className="flex items-center gap-x-3">
                            <label className="font-lato pl-2 text-custom font-semibold text-black">Pickup-Time</label>
                            <RiArrowDropDownLine className="text-blue-500 text-2xl" />
                        </p>
                        <input
                            type="time"
                            value={bookingInfo.selectedTime}
                            onChange={(e) => { handleTimeChange("selectedTime", e.target.value) }}
                            className="w-full text-xl font-bold pl-1 border-none focus:outline-none"
                        />
                    </div>

                    {/* Drop Date */}
                    <div className="text-start border-r-[1px] border-gray-200 p-1 col-span-1">
                        <p className="flex items-center gap-x-3">
                            <label className="font-lato pl-2 text-custom font-semibold text-gray-600">Drop Date</label>
                            <RiArrowDropDownLine className="text-blue-500 text-2xl" />
                        </p>
                        <input
                            type="date"
                            value={bookingInfo.dropoffDate}
                            onChange={(e) => handleDateChange('dropoffDate', e.target.value)}
                            className="w-full text-xl font-bold text-[#757575] pl-1 border-none focus:outline-none"
                        />
                    </div>

                    {/* Drop Time */}
                    <div className="text-start border-r-[1px] border-gray-200 p-1 col-span-1">
                        <p className="flex items-center gap-x-3">
                            <label className="font-lato pl-2 text-custom font-semibold text-gray-600">Drop-Time</label>
                            <RiArrowDropDownLine className="text-blue-500 text-2xl" />
                        </p>
                        <input
                            type="time"
                            value={bookingInfo.dropoffTime}
                            onChange={(e) => { handleTimeChange("dropoffTime", e.target.value) }}
                            className="w-full text-xl font-bold pl-1 border-none focus:outline-none"
                        />
                    </div>
                    <button
                        onClick={handleSearch}
                        className="absolute top-[7rem] left-1/2 transform -translate-x-1/2 w-[146px] py-1 px-5 rounded-full shadow-lg text-lg text-white"
                        style={{ background: "linear-gradient(90.61deg, #53B2FE 0%, #065AF3 100%)" }}
                    >
                        Search
                    </button>
                </section>
                {/* Small screen ui */}
                <div className="sm:flex xs:flex xsm:flex pb-4 visible flex-col lg:hidden my-4 md:hidden w-[90vw] mx-auto space-y-2 lg:h-[80vh] md:h-[80vh] xsm:h-fit ">
                    <div className="relative flex items-center w-full text-start md:border-b-[1px] font-semibold p-1 xsm:bg-[#EDEDED] rounded-md border-[0.8px] border-[#E7E7E7]">
                        <IoLocation className="w-[2rem]" />
                        <div className="w-full">
                            <label className="font-lato pl-2 text-[0.62rem] font-semibold text-[#4A4A4A]">From</label>
                            <select
                                value={bookingInfo.fromLocation}
                                className="font-lato text-[0.8rem] focus:ring-0 focus:outline-none bg-[#EDEDED] placeholder:font-normal font-semibold leading-[20.64px] text-left w-full pl-3 text-[#4A4A4A]"
                                onChange={(e) => handleInputChange("fromLocation", e.target.value)}
                            >
                                <option className="text-[#4A4A4A]" value="" disabled>Select Location</option>
                                <option value="Udaipur" className="text-[#4A4A4A]">Udaipur</option>
                                <option value="Bhopal" className="text-[#4A4A4A]">Bhopal</option>
                                <option value="Indore" className="text-[#4A4A4A]">Indore</option>
                            </select>
                        </div>
                    </div>
                    <div className="w-full flex items-center text-start border-[0.8px]  border-[#E7E7E7]  xsm:bg-[#EDEDED] rounded-md">
                        <GrLocationPin className="w-[2rem]" />
                        <div>
                            <label className="font-lato pl-2 text-[0.62rem] font-semibold text-[#4A4A4A]">To</label>
                            <input
                                type="text"
                                placeholder="Select Destination"
                                value={bookingInfo.toLocation}
                                className="font-lato text-[0.8rem] placeholder:text-[0.8rem] text-[#4A4A4A] focus:ring-0 focus:outline-none bg-[#EDEDED] placeholder:font-normal font-semibold leading-[20.64px] text-left w-full pl-3"
                                onChange={(e) => handleInputChange("toLocation", e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="flex w-full grid-cols-2 gap-2">
                        <div className="w-full flex items-center col-span-1 text-start border-[0.8px] border-[#E7E7E7] p-1 xsm:bg-[#EDEDED] rounded-md">
                            <MdDateRange className="w-[2rem]" />
                            <div className="w-full">
                                <p className="flex items-center gap-x-3">
                                    <label className="font-lato pl-2 text-[0.62rem] font-semibold text-[#4A4A4A]">Pickup Date</label>
                                </p>
                                <input
                                    type="date"
                                    value={bookingInfo.selectedDate}
                                    onChange={(e) => handleDateChange('selectedDate', e.target.value)}
                                    className="w-full text-[0.8rem] font-semibold pl-1 bg-[#EDEDED] border-none focus:outline-none"
                                />
                            </div>
                        </div>
                        <div className="w-full flex items-center col-span-1 text-start border-[0.8px] border-[#E7E7E7] p-1 xsm:bg-[#EDEDED] rounded-md">
                            <IoIosTimer className="w-[2rem]" />
                            <div className="w-full">
                                <p className="flex items-center gap-x-3">
                                    <label className="font-lato pl-2 text-[0.62rem] font-semibold text-[#4A4A4A]">Pickup Time</label>
                                </p>
                                <input
                                    type="time"
                                    value={bookingInfo.selectedTime}
                                    onChange={(e) => handleTimeChange("selectedTime", e.target.value)}
                                    className="w-full text-[0.8rem] font-bold pl-1 border-none focus:outline-none bg-[#EDEDED]"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-2 gap-2 w-full">
                        {/* Drop Date Section */}
                        <div className="w-full flex items-center col-span-1 text-start border-[0.8px] border-[#E7E7E7] p-1 xsm:bg-[#EDEDED] rounded-md">
                            <MdDateRange className="w-[2rem]" />
                            <div className="w-full">
                                <label className="font-lato pl-2 text-[0.62rem] font-semibold text-[#4A4A4A]">Drop Date</label>
                                <input
                                    type="date"
                                    value={bookingInfo.dropoffDate}
                                    onChange={(e) => handleDateChange('dropoffDate', e.target.value)}
                                    className="w-full text-[0.8rem] font-semibold pl-1 bg-[#EDEDED] border-none focus:outline-none"
                                />
                            </div>
                        </div>

                        <div className="w-full flex items-center col-span-1 text-start border-[0.8px] border-[#E7E7E7] p-1 xsm:bg-[#EDEDED] rounded-md">
                            <IoIosTimer className="w-[2rem]" />
                            <div className="w-full">
                                <label className="font-lato pl-2 text-[0.62rem] font-semibold text-[#4A4A4A]">Drop Time</label>
                                <input
                                    type="time"
                                    value={bookingInfo.dropoffTime}
                                    onChange={(e) => handleTimeChange("dropoffTime", e.target.value)}
                                    className="w-full text-[0.8rem] font-bold pl-1 bg-[#EDEDED] border-none focus:outline-none"
                                />
                            </div>
                        </div>
                    </div>
                    <button
                        onClick={handleSearch}
                        className="w-[90vw] cursor-pointer py-1 px-5 rounded-full shadow-lg text-lg text-white"
                        style={{ background: "linear-gradient(90.61deg, #53B2FE 0%, #065AF3 100%)" }}
                    >
                        Search
                    </button>
                </div>
            </div>
            {
                alerModel !== null && (
                    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50">
                        <div className="bg-white rounded-lg shadow-lg p-6 w-full xsm:w-[90vw] md:max-w-md text-center">
                            <h2 className=" text-[14px] md:text-xl font-bold text-gray-800 mb-4">Alert</h2>
                            <p className="text-gray-600 text-[11px] md:text-[14px] mb-6">{alerModel}</p>
                            <button
                                className="bg-blue-500 text-[11px] md:text-[14px] hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded"
                                onClick={onClose}
                            >
                                OK
                            </button>
                        </div>
                    </div>
                )
            }
            {timeAlertModel !== null && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                    <div className="bg-gradient-to-r from-blue-100 to-teal-50 rounded-lg shadow-2xl p-8 w-full xsm:w-[90vw] md:max-w-md text-center relative">
                        <div className="absolute top-0 right-0 p-2">
                            <button
                                onClick={handleCloseTime}
                                className="bg-transparent text-gray-600 hover:text-gray-900 text-xl font-semibold"
                            >
                                <span>&times;</span>
                            </button>
                        </div>
                        <div className="relative mb-4">
                            <span className="absolute -top-7 left-1/2 transform -translate-x-1/2 bg-red-500 text-white rounded-full w-8 h-8 flex justify-center items-center text-3xl font-bold">
                                !
                            </span>
                        </div>
                        <h2 className=" text-xl md:text-2xl font-extrabold text-teal-700 mb-4">Important </h2>
                        <p className=" text-[11px] md:text-[15px] text-gray-700 mb-6 font-medium">
                            Pickup date and time must be at least 4 hours later than the current time.
                        </p>

                    </div>
                </div>
            )}


        </section >

    );
};

export default BookingDate;
