import React from 'react'
import GalleryImageUpload from '../Components/Core/Gallery/GalleryImageUpload'
import AllGalleryImagesList from '../Layout/tables/Gallery/AllGalleryImages'
import SEOHelmet from '../Components/common/SeoHelmet'

const UploadToGallery = () => {
  return (
    <>
            <SEOHelmet title="Upload to Gallery | TSJ Car Rentals"/>

    <GalleryImageUpload/>
    <AllGalleryImagesList/>
    </>
  )
}

export default UploadToGallery