import React, { Fragment, useEffect, useState } from "react";
import styles from "./AdminNestedNavbarStyle.module.scss";
import {
  Box,
  Grid,
  ListItemButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Link,
} from "@mui/material";
import { Outlet, NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";
import SEOHelmet from "../SeoHelmet";

const AdminNestedNavabar = ({ BookingPath }) => {
  return (
    <div>
      <SEOHelmet title="Vehicles List | TSJ Car Rentals" />
      <Fragment>
        <Grid className={styles.employeePageContainer}>
          <Stack direction="row" spacing={3}>
            {BookingPath?.map((type) => (
              <NavLink
                key={type.id}
                to={type.link}
                style={{
                  backgroundColor: "#3FACE2",
                  padding: "8px 16px",
                  borderRadius: "4px",
                  color: "#fff",
                  textDecoration: "none",
                }}
                activeStyle={{
                  backgroundColor: "#3FACEE",
                }}
              >
                <Typography>{type.title}</Typography>
              </NavLink>
            ))}
          </Stack>
          <Outlet />
        </Grid>
      </Fragment>
    </div>
  );
};

export default AdminNestedNavabar;
