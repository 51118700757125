import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BASE_API } from '../../../Services/apis';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import SEOHelmet from '../../../Components/common/SeoHelmet';

const TTBookingTable = () => {
    const [bookings, setBookings] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchBookings = async () => {
            try {
                const token = JSON.parse(localStorage.getItem('token'));
                const response = await axios.get(`${BASE_API}/user/my-tour-and-package-bookings`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setBookings(response?.data?.bookings || []);
            } catch (error) {
                console.error("Error fetching bookings:", error);
            }
        };

        fetchBookings();
    }, []);

    const handleDetailsClick = (bookingId) => {
        localStorage.setItem("vehicleDetail", "TourTravelVehicle")

        navigate(`/user/booking-details/tt/${bookingId}`);
    };

    return (
        <div className="">
            <SEOHelmet title="User Tour&Travel Booking | TSJ Car Rentals"/>
              
            <div className="overflow-x-auto">
                <table className="w-full border-collapse bg-white shadow-lg">
                    <thead>
                        <tr className="bg-gradient-to-r from-blue-600 to-blue-400 text-white">
                            <th className="px-4 py-2 whitespace-nowrap">Package Name</th>
                            <th className="px-4 py-2 whitespace-nowrap">Destination</th>
                            <th className="px-4 py-2 whitespace-nowrap">Booking Date</th>
                            <th className="px-4 py-2 whitespace-nowrap">Duration</th>
                            <th className="px-4 py-2 whitespace-nowrap">Total Price</th>
                            <th className="px-4 py-2 whitespace-nowrap">Payment Status</th>
                            <th className="px-4 py-2 whitespace-nowrap">Booking Status</th>
                            <th className="px-4 py-2 whitespace-nowrap">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {bookings.length === 0 ? (
                            // Display a message if no bookings are present
                            <tr>
                                <td colSpan="8" className="text-center text-gray-500 py-4">
                                    No bookings available.
                                </td>
                            </tr>
                        ) : (
                            bookings.map((booking) => (
                                <tr
                                    key={booking._id} // Unique identifier for each booking
                                    className="odd:bg-gray-100 even:bg-gray-50 hover:bg-gray-200" // Styling for alternate row colors and hover effect
                                >
                                    {/* Package Name Column */}
                                    <td className="border px-4 py-2 text-gray-700">{booking.packageName}</td>

                                    {/* Destination Column */}
                                    <td className="border px-4 py-2 text-gray-700">{booking.destination}</td>

                                    {/* Booking Date Column */}
                                    <td className="border px-4 py-2 text-gray-700">
                                        {new Date(booking.bookingDate).toLocaleDateString()} {/* Format the booking date */}
                                    </td>

                                    {/* Duration Column */}
                                    <td className="border px-4 py-2 text-gray-700">
                                        {`${booking.durationInDays} Days, ${booking.durationInNight} Nights`} {/* Show duration in days and nights */}
                                    </td>

                                    {/* Total Price Column */}
                                    <td className="border px-4 py-2 text-gray-700">₹{booking.totalPrice}</td>

                                    {/* Payment Status Column */}
                                    <td className="border px-4 py-2 text-gray-700">{booking.paymentStatus}</td>

                                    {/* Booking Status Column */}
                                    <td
                                        className={`border px-4 py-2
          ${booking.bookingStatus === 'Pending' ? 'bg-yellow-100 text-yellow-700' : ''}
          ${booking.bookingStatus === 'Confirmed' ? 'bg-green-100 text-green-700' : ''}
          ${booking.bookingStatus === 'Cancelled' ? 'bg-red-100 text-red-700' : ''}
          ${booking.bookingStatus === 'Completed' ? 'bg-blue-100 text-blue-700' : ''}
          text-gray-700`}
                                    >
                                        {booking.bookingStatus}
                                    </td>
                                    <td>
                                        <button
                                            className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600 whitespace-nowrap"
                                            onClick={() => handleDetailsClick(booking._id)}
                                        >
                                            View Details
                                        </button>
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default TTBookingTable;
