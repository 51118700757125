import React, { useEffect, useState } from "react";
import Navbar from "../Components/common/Navbar";
import BookingDate from "../Components/Core/Home/BookingDate"
import CabServices from "../Components/Core/Home/Services";
import Whybook from "../Components/Core/Home/Whybook";
import AboutTSJ from "../Components/Core/Home/AboutTsj";
import c1 from "../Assests/newHomeImgs/Europe.jpg"
import c2 from "../Assests/newHomeImgs/Getty.jpg"
import c3 from "../Assests/newHomeImgs/Multiple.jpeg"
import c4 from "../Assests/newHomeImgs/girl.jpg"

import road from "../Assests/newHomeImgs/Background.png"
import PopularPlaces from "../Components/Core/Home/PopularPlace";
import ServicesSection from "../Components/Core/Home/ServicesSection";
import Footer from "../Components/common/Footer";
import Banner from "../Components/common/banner";
import { motion } from "framer-motion";
import CarCardComp from "../Components/common/CarCardComp";
import GoogleReviews from "../Components/common/GoogleReview";
import LatestBlog from "../Components/Core/Home/Blog";
import { Link, useLocation } from "react-router-dom";
import {allFeaturedVehicleList} from "../Services/common";
import SEOHelmet from "../Components/common/SeoHelmet";

const HomePage = () => {
    const location = useLocation();
    const [featuredVehicle,setFeaturedVehicle]=useState([])
    const images = [
        {
            id: 1,
            icon: c1,
        },
        {
            id: 2,
            icon: c2,
        },
        {
            id: 3,
            icon: c3,
        },
        {
            id: 4,
            icon: c4,
        },
    ];
    const cars = [
        {
            brand: "Tata",
            model: "Nexon",
            year: 2023,
            transmission: "Automatic",
            mileage: "16",
            fuelType: "Diesel",
            persons: 5,
            ac: true,
            location: "Bhopal (M.P)",
            pricePerHour: 400,
            extraKmFare: 15,
            available: true,
            imageUrl:
                "https://stimg.cardekho.com/images/carexteriorimages/930x620/Tata/Nexon/11104/1697698470038/front-left-side-47.jpg",
        },
        {
            brand: "Mahindra",
            model: "XUV700",
            year: 2023,
            transmission: "Automatic",
            mileage: "15",
            fuelType: "Diesel",
            persons: 7,
            ac: true,
            location: "Indore (M.P)",
            pricePerHour: 600,
            extraKmFare: 20,
            available: true,
            imageUrl:
                "https://stimg.cardekho.com/images/carexteriorimages/930x620/Mahindra/XUV700/10789/1705388477954/front-left-side-47.jpg",
        },
        {
            brand: "Volkswagen",
            model: "Virtus",
            year: 2023,
            transmission: "Automatic",
            mileage: "18",
            fuelType: "Petrol",
            persons: 5,
            ac: true,
            location: "Indore (M.P)",
            pricePerHour: 450,
            extraKmFare: 16,
            available: true,
            imageUrl:
                "https://stimg.cardekho.com/images/carexteriorimages/930x620/Volkswagen/Virtus/10619/1722405056835/front-left-side-47.jpg",
        },
    ];
    useEffect(() => {
        if (location.hash) {
            const element = document.querySelector(location.hash);
            if (element) {
                element.scrollIntoView({ behavior: "smooth" });
            }
        }
    }, [location]);

       useEffect(()=>{
        const fetchFeaturedVehicle=async()=>{
            try {
                const response =await allFeaturedVehicleList();
                if(response.status===200){
                    setFeaturedVehicle(response.data.featuredVehicles)
                }
            } catch (error) {
                console.log(error)
            }
        }
        fetchFeaturedVehicle()
       },[]);

    return (

        <motion.div
            className="xsm:w-[100vw] md:w-[100%]"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
        >
            <SEOHelmet title="Home | TSJ Car Rentals" />

            <Navbar />

            <section className="hidden font-poppins p-6">
                <div className="w-full flex flex-col gap-5 items-start">

                    <h1 className="text-[#111928] text-[39.4px] font-[700] leading-[47.29px] text-left   decoration-skip-ink decoration-underline xsm:w-[90%] xs:w-[95%] sm:w-[70%] font-poppins">
                        Find the Perfect Car for <span className="text-[#007BFF]">Your Trip</span>
                    </h1>

                    <p className="text-[13.13px] font-[400] leading-[19.7px] tracking-[0.41px] text-left  decoration-skip-ink decoration-underline font-body-large">
                        Quick, easy, and at the best price. Whether you're planning a weekend getaway or a cross-country adventure, our diverse fleet and exceptional service ensure you get on the road effortlessly.
                    </p>
                    <Link to="/login">
                        <button
                            className="w-[121.97px] h-[41.34px] px-[22.99px] py-[10.67px] gap-[8.21px] rounded-[4px] font-[600]  bg-[#007BFF] text-white text-sm "
                        >
                            Get Start
                        </button>
                    </Link>
                </div>
            </section>
            <Banner />


            <section className="lg:my-5 md:my-5 xsm:mt-[14rem] sm:mt-[8rem]">
                <motion.section
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.8 }}
                    className="text-black font-font1 py-3"
                >
                    <CabServices />
                </motion.section>
            </section>
            <section className="bg-[#F5F5F574] font-poppins p-6">
                <div className="flex flex-col space-y-6 py-5 md:w-full xsm:w-[80vw]  mx-auto">
                    <h1 className="font-montserrat text-xl font-semibold xsm:leading-5  md:leading-[30px]  text-black  text-center h-6">
                        Select and book a car rental with Tsj
                    </h1>
                    <p className="font-montserrat text-[1rem] md:text-[1rem] sm:text-[0.9rem] xsm:text-[0.8rem] text-[#484848] font-medium leading-custom24 text-center">
                        You can pick a self-drive car rental in Indore from Tsj, You have the freedom to move around and explore places with Tsj</p>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-4 p-1 md:p-4  w-[90vw] md:w-[80vw] mx-auto">
                    {featuredVehicle.map((car, index) => (
                        <CarCardComp key={index} {...car.vehicleId} />
                    ))}
                </div>
            </section>

            <Whybook />
            <section>
                <AboutTSJ />
                <img src={road} />

                <motion.section
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.8 }}
                    className="text-black font-font1 py-3"
                >
                    <h2 className="text-center text-3xl font-semibold leading-[36px] md:mb-10 my-[1.5rem] font-montserrat">
                        Customer Testimonial
                    </h2>

                    <div className="grid gap-10 justify-items-center grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 md:grid-cols-4">
                        {images.map((feature) => (
                            <motion.div
                                key={feature.id}
                                initial={{ scale: 0.8 }}
                                whileInView={{ scale: 1 }}
                                transition={{ duration: 0.3 }}
                                className="text-center"
                            >
                                <div className="flex justify-center">
                                    <img
                                        src={feature.icon}
                                        alt={feature.title}
                                        className="w-[15.625rem] h-[10.625rem]"
                                    />
                                </div>
                                <h3 className="text-lg font-semibold font-montserrat mt-4">
                                    {feature.title}
                                </h3>
                                <p className="text-sm max-w-[15.625rem] font-montserrat mx-auto mt-2">
                                    {feature.description}
                                </p>
                            </motion.div>
                        ))}
                    </div>

                </motion.section>


                {/* <section className="bg-[#EDEDED] md:h-[15rem] xsm:h-auto grid place-content-center">
                    <ServicesSection />
                </section> */}
            </section>
            <GoogleReviews />
            <LatestBlog />
            <Footer />
        </motion.div>
    );
};

export default HomePage;
