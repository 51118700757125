import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { BASE_API } from '../Services/apis';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { setUser } from '../slices/UserSlices';
import SEOHelmet from '../Components/common/SeoHelmet';

const AdminLoginForm = () => {
    const location = useLocation();
    const dispatch = useDispatch();

    const loginType = location.pathname.split("/")[1];
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });

    const [formErrors, setFormErrors] = useState({
        email: '',
        password: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const validateForm = () => {
        let errors = {};
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!formData.email) {
            errors.email = 'Email is required';
        } else if (!emailRegex.test(formData.email)) {
            errors.email = 'Email is invalid';
        }
        if (!formData.password) {
            errors.password = 'Password is required';
        }

        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            try {
                const response = loginType === 'admin' ? await fetch(`${BASE_API}/admin/login`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(formData),
                }) : await fetch(`${BASE_API}/manager/login`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(formData),
                })
                const data = await response.json();

                if (response.ok) {
                    dispatch(setUser(data));
                    localStorage.setItem('user', JSON.stringify(data));
                    localStorage.setItem("token", JSON.stringify(data?.token))
                    toast.success('Login Successful!');
                    navigate(`/${loginType}/manage`);
                } else {
                    toast.error(data.message || 'Login Failed. Please try again.');
                }
            } catch (error) {
                console.error('Error during login:', error);
                toast.error('An error occurred. Please try again later.');
            }
        }
    };

    return (
        <div className="text-start w-full max-w-md mx-auto mt-10 p-6 bg-gray-100 rounded-lg shadow-md">
            <SEOHelmet title={`${loginType} Login | TSJ Car Rentals`} />
            <h2 className="text-3xl font-bold mb-6 text-center text-blue-600">Login</h2>
            <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                    <label className="block text-gray-700 text-sm font-semibold mb-1" htmlFor="email">
                        Email
                    </label>
                    <input
                        type="email"
                        name="email"
                        id="email"
                        value={formData.email}
                        onChange={handleChange}
                        className={`shadow-md appearance-none border rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 ${formErrors.email ? 'border-red-500' : 'border-gray-300'
                            }`}
                        placeholder="Enter your email"
                    />
                    {formErrors.email && <p className="text-red-500 text-xs mt-1">{formErrors.email}</p>}
                </div>

                <div>
                    <label className="block text-gray-700 text-sm font-semibold mb-1" htmlFor="password">
                        Password
                    </label>
                    <input
                        type="password"
                        name="password"
                        id="password"
                        value={formData.password}
                        onChange={handleChange}
                        className={`shadow-md appearance-none border rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 ${formErrors.password ? 'border-red-500' : 'border-gray-300'
                            }`}
                        placeholder="Enter your password"
                    />
                    {formErrors.password && <p className="text-red-500 text-xs mt-1">{formErrors.password}</p>}
                </div>

                <div>
                    <button
                        type="submit"
                        className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-blue-400 transition duration-200 w-full"
                    >
                        Log In
                    </button>
                </div>
            </form>
        </div>
    );
};

export default AdminLoginForm;
