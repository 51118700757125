import React, { useEffect, useState } from 'react';
import {
    Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button, Box, Chip
} from '@mui/material';
import toast from 'react-hot-toast';
import { OnboardNewTTVehicle, getAllOnboardTTVehicleList } from '../../Services/AddVehicleDetails';

const TourPackageModal = ({ open, onClose }) => {
    const [mealInput, setMealInput] = useState('');
    const [activityInput, setActivityInput] = useState('');
    const [formData, setFormData] = useState({
        packageName: '',
        description: '',
        destination: '',
        durationInDays: '',
        durationInNight: '',
        accommodationType: '',
        transportation: '',
        mealsIncluded: [],
        activities: [],
        packagePrice: '',
        cancellationPolicy: '',
        groupSize: '',
        maxAdults: '',
        maxChildren: '',
        titleImg: '',
        imgs: [],
        contactNumber: '',
        source:''
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleMealAdd = () => {
        if (mealInput.trim() && !formData.mealsIncluded.includes(mealInput)) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                mealsIncluded: [...prevFormData.mealsIncluded, mealInput],
            }));
            setMealInput('');
        }
    };

    const handleActivityAdd = () => {
        if (activityInput.trim() && !formData.activities.includes(activityInput)) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                activities: [...prevFormData.activities, activityInput],
            }));
            setActivityInput('');
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log("Form data:", formData);
        try {
            // Assuming the onboard package service function
            await OnboardNewTTVehicle(formData); // Replace with actual service function
            toast.success("Successfully added package");
            await getAllOnboardTTVehicleList();
            setFormData({
                packageName: '',
                description: '',
                destination: '',
                durationInDays: '',
                durationInNight: '',
                accommodationType: '',
                transportation: '',
                mealsIncluded: [],
                activities: [],
                packagePrice: '',
                cancellationPolicy: '',
                groupSize: '',
                maxAdults: '',
                maxChildren: '',
                titleImg: '',
                imgs: [],
                contactNumber: ''
            });
            onClose();
        } catch (error) {
            console.error("API Error:", error);
        }
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>Add New Package</DialogTitle>
            <DialogContent>
                <Box component="form" onSubmit={handleSubmit} sx={{ display: 'grid', gap: 2 }}>
                    <TextField label="Package Name" name="packageName" value={formData.packageName} onChange={handleChange} />
                    <TextField label="Special Notes" name="description" multiline value={formData.description} onChange={handleChange} />
                    <TextField label="source" name="source" value={formData.source} onChange={handleChange} />
                    <TextField label="Destination" name="destination" value={formData.destination} onChange={handleChange} />
                    <TextField label="Duration in Days" name="durationInDays" type="number" value={formData.durationInDays} onChange={handleChange} />
                    <TextField label="Duration in Nights" name="durationInNight" type="number" value={formData.durationInNight} onChange={handleChange} />
                    <TextField label="Accommodation Type" name="accommodationType" value={formData.accommodationType} onChange={handleChange} />
                    <TextField label="Transportation" name="transportation" value={formData.transportation} onChange={handleChange} />

                    {/* Meals Included */}
                    <Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <TextField label="Add Meal" value={mealInput} onChange={(e) => setMealInput(e.target.value)} fullWidth />
                            <Button variant="contained" onClick={handleMealAdd}>Add</Button>
                        </Box>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, mt: 1 }}>
                            {formData.mealsIncluded.map((meal, index) => (
                                <Chip key={index} label={meal} />
                            ))}
                        </Box>
                    </Box>

                    {/* Activities */}
                    <Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <TextField label="Add Activity" value={activityInput} onChange={(e) => setActivityInput(e.target.value)} fullWidth />
                            <Button variant="contained" onClick={handleActivityAdd}>Add</Button>
                        </Box>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, mt: 1 }}>
                            {formData.activities.map((activity, index) => (
                                <Chip key={index} label={activity} />
                            ))}
                        </Box>
                    </Box>

                    <TextField label="Package Price" name="packagePrice" type="number" value={formData.packagePrice} onChange={handleChange} />
                    <TextField label="Cancellation Policy" name="cancellationPolicy" value={formData.cancellationPolicy} onChange={handleChange} />
                    <TextField label="Group Size" name="groupSize" type="number" value={formData.groupSize} onChange={handleChange} />
                    <TextField label="Max Adults" name="maxAdults" type="number" value={formData.maxAdults} onChange={handleChange} />
                    <TextField label="Max Children" name="maxChildren" type="number" value={formData.maxChildren} onChange={handleChange} />
                    <TextField label="Title Image URL" name="titleImg" value={formData.titleImg} onChange={handleChange} />
                    <TextField label="Image URLs (comma-separated)" name="imgs" value={formData.imgs.join(',')} onChange={(e) => setFormData({ ...formData, imgs: e.target.value.split(',') })} />
                    <TextField label="Contact Number" name="contactNumber" value={formData.contactNumber} onChange={handleChange} />
                    <DialogActions>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button type="submit">Add Package</Button>
                    </DialogActions>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default TourPackageModal;
