import React, { Fragment } from "react";
import styles from "./VehiclesPage.module.scss";
import {Grid, Stack, Typography } from "@mui/material";
import { Outlet,Link } from "react-router-dom"
import { manageTypes } from "../data/Tour&Travels";

import SEOHelmet from "../Components/common/SeoHelmet";
const BookingTypes = () => {
    return (
        <Fragment>
              <SEOHelmet title="User Bookings | TSJ Car Rentals">
              </SEOHelmet>
            <Grid className={styles.employeePageContainer}>
                <Stack direction="row" spacing={2}>
                    {manageTypes.map((type) => (
                        <Link key={type.id} to={type.link} underline="hover" color="inherit"
                            style={{
                                backgroundColor: '#3FACE2',
                                padding: '8px 16px',
                                borderRadius: '4px',
                                color: '#fff',
                                textDecoration: 'none',
                                '&:hover': {
                                    backgroundColor: '#3FACEE',
                                    underline: "none"
                                },
                            }}
                        >
                            <Typography>{type.title}</Typography>
                        </Link>
                    ))}
                </Stack>
                <Outlet />

            </Grid>

        </Fragment>
    );
};

export default BookingTypes;