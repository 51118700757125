import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { getAllGalleryImages } from '../Services/common'
import Navbar from '../Components/common/Navbar';
import Footer from '../Components/common/Footer';
import SEOHelmet from '../Components/common/SeoHelmet';

const MyGallery = () => {
    const [gallery, setGallery] = useState([]);

    useEffect(() => {
        const fetchGallery = async () => {
            try {
                const response = await getAllGalleryImages()
                setGallery(response?.data?.items);
            } catch (error) {
                console.error("Error fetching bookings:", error);
            }
        };

        fetchGallery();
    }, []);
    return (
        <>
            <Navbar />
            <section className='w-[100vw]'>
                <SEOHelmet title="Gallery | TSJ Car Rentals">
                </SEOHelmet>
                <div className="p-4 w-[80vw] mx-auto my-10">
                    <h1 className="text-4xl font-bold mb-4 text-center">Gallery</h1>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4">
                        {gallery.map((photo, index) => (
                            <motion.div
                                key={index}
                                className="overflow-hidden rounded-lg shadow-lg group relative"
                                initial={{ opacity: 0, scale: 0.8 }}
                                animate={{ opacity: 1, scale: 1 }}
                                transition={{ duration: 0.5 }}
                            >
                                {/* Image */}
                                <motion.img
                                    src={photo.mediaUrl}
                                    alt={photo.title}
                                    className="w-full h-[350px] object-cover gallery-item"
                                    whileHover={{ scale: 1.05 }}
                                    transition={{ type: "spring", stiffness: 300 }}
                                />

                                {/* Title Overlay */}
                                <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                    <p className="text-white text-lg font-bold">{photo.title}</p>
                                </div>
                            </motion.div>
                        ))}
                    </div>
                </div>
                <Footer />
            </section>
        </>

    );
}

export default MyGallery;
