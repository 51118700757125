import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FaSearchLocation, FaMobileAlt } from "react-icons/fa";
import { IoMdAdd } from "react-icons/io";
import { IoCloseCircleOutline } from "react-icons/io5"

const FindTripForm = ({ query, fetchVehicleData ,setAlertModel,alerModel,handlefillModel,onClose}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    pickUpLocation: searchParams.get("pickupLocation") || "",
    pickUpDate: searchParams.get("pickupDate") || "",
    pickUpTime: searchParams.get("pickupTime") || "",
    dropAddress: searchParams.get("dropLocation") || "",
    dropDate: searchParams.get("dropoffDate") || "",
    dropTime: searchParams.get("dropoffTime") || "",
    alternateMobile: searchParams.get("alternateMobile") || "",
    inBetweenCities: JSON.parse(searchParams.get("inBetweenCities")) || [],
  });

  useEffect(() => {
    setFormData({
      pickUpLocation: searchParams.get("pickupLocation") || "",
      pickUpDate: searchParams.get("pickupDate") || "",
      pickUpTime: searchParams.get("pickupTime") || "",
      dropAddress: searchParams.get("dropLocation") || "",
      dropDate: searchParams.get("dropoffDate") || "",
      dropTime: searchParams.get("dropoffTime") || "",
      alternateMobile: searchParams.get("alternateMobile") || "",
      inBetweenCities: JSON.parse(searchParams.get("inBetweenCities")) || [],
    });
  }, [searchParams]);

  useEffect(() => {
    query(formData)
  }, [searchParams, formData])

  const handleInputChange = (field, value) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

 
  

  const handleCityChange = (index, value) => {
    const updatedCities = [...formData.inBetweenCities];
    updatedCities[index].address = value;
    setFormData((prev) => ({
      ...prev,
      inBetweenCities: updatedCities,
    }));
  };

  const handleAddCity = () => {
    setFormData((prev) => ({
      ...prev,
      inBetweenCities: [...prev.inBetweenCities, { address: "" }],
    }));
  };

  const handleRemoveCity = (index) => {
    const updatedCities = formData.inBetweenCities.filter((_, i) => i !== index);
    setFormData((prev) => ({
      ...prev,
      inBetweenCities: updatedCities,
    }));
  };
  const isFormValid =

    !Array.isArray(formData.inBetweenCities) ||
    formData.inBetweenCities.some((city) => !city.address || city.address.trim() === "");



  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.pickUpLocation) {
      handlefillModel("Please fill the source location.");
      return;
    } else if (!formData.dropAddress) {
      handlefillModel("Please fill the destination location.");
      return;
    }

    else if (!formData.pickUpDate) {
      handlefillModel("Please select the PickUp date.");
      return;
    } else if (!formData.pickUpTime) {
      handlefillModel("Please select the PickUp Time time.");
      return;
    }
    else if (!formData.dropDate) {
      handlefillModel("Please select the drop-off date.");
      return;
    }
    else if (new Date(formData.pickUpDate) <= new Date(new Date().setDate(new Date().getDate() - 1))) {
      handlefillModel("Pickup date cannot be Back date.");
      return;
    }
    else if (new Date(formData.pickUpDate) > new Date(formData.dropDate)) {
      handlefillModel("Pickup date must be less than or equal to the drop-off date.");
      return;
    }
    else if (!formData.dropTime) {
      handlefillModel("Please select the drop-off time.");
      return;
    }
   


    const params = {
      pickupLocation: formData.pickUpLocation,
      pickupDate: formData.pickUpDate,
      pickupTime: formData.pickUpTime,
      dropLocation: formData.dropAddress,
      dropoffDate: formData.dropDate,
      dropoffTime: formData.dropTime,
      alternateMobile: formData.alternateMobile,
      inBetweenCities: JSON.stringify(formData.inBetweenCities),
    };

    fetchVehicleData()
    setSearchParams(params);
    navigate(`/Trip/TaxiPackages?${new URLSearchParams(params).toString()}`);
  };

  return (
    <form
      onSubmit={handleSubmit}
      style={{
        borderColor: "rgb(243, 243, 243)",
        fontFamily:
          'UberMoveText, system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif',
      }}
      className="border-2 shadow-lg bg-white rounded-lg p-6 text-[1rem]"
    >
      <h2 className="text-lg font-semibold mb-4">Find a trip</h2>

      <div className="mb-3 flex items-center bg-gray-100 rounded p-2 cursor-pointer hover:bg-gray-200">
        <FaSearchLocation className="text-xl mr-2" />
        <select
          value={formData.pickUpLocation}
          className="bg-transparent focus:outline-none w-full cursor-pointer"
          onChange={(e) => handleInputChange("pickUpLocation", e.target.value)}
        >
          <option className="text-[#4A4A4A]" value="" disabled>
            Select Location
          </option>
          <option value="Jaipur" className="text-[#4A4A4A]">
            Udaipur
          </option>
          <option value="Bhopal" className="text-[#4A4A4A]">
            Bhopal
          </option>
          <option value="Indore" className="text-[#4A4A4A]">
            Indore
          </option>
        </select>
      </div>

      <div className="mb-3 flex flex-col md:flex-row gap-2">
        <input
          type="date"
          className=" md:w-1/2 bg-gray-100 rounded p-2 focus:outline-none"
          value={formData.pickUpDate}
          onChange={(e) => handleInputChange("pickUpDate", e.target.value)}
        />
        <input
          type="time"
          className="md:w-1/2 bg-gray-100 rounded p-2 focus:outline-none"
          value={formData.pickUpTime}
          onChange={(e) => handleInputChange("pickUpTime", e.target.value)}
        />
      </div>

      <div className="mb-3 flex items-center bg-gray-100 rounded p-2 cursor-pointer hover:bg-gray-200">
        <input
          type="text"
          placeholder="Drop-off location"
          className="bg-transparent  focus:outline-none w-full cursor-pointer"
          value={formData.dropAddress}
          onChange={(e) => handleInputChange("dropAddress", e.target.value)}
        />
      </div>

      <div className="mb-3 flex-col w-full md:flex-row flex gap-2">
        <input
          type="date"
          className=" md:w-1/2 bg-gray-100 rounded p-2 focus:outline-none"
          value={formData.dropDate}
          onChange={(e) => handleInputChange("dropDate", e.target.value)}
        />
        <input
          type="time"
          className="md:w-1/2 bg-gray-100 rounded p-2 focus:outline-none"
          value={formData.dropTime}
          onChange={(e) => handleInputChange("dropTime", e.target.value)}
        />
      </div>


      <div className="mb-3 flex flex-col w-full items-center">
        <h3 className="text-md font-semibold mb-2">In-Between Cities 3</h3>
        {formData.inBetweenCities.map((city, index) => (
          <div
            key={index}
            className="mb-2 w-full flex  items-center bg-gray-100 rounded p-2 cursor-pointer hover:bg-gray-200"
          >
            <input
              type="text"
              placeholder={`City ${index + 1}`}
              className="bg-transparent focus:outline-none w-full cursor-pointer"
              value={city.address}
              onChange={(e) => handleCityChange(index, e.target.value)}
            />
            <IoCloseCircleOutline
              onClick={() => handleRemoveCity(index)}
            />
          </div>
        ))}
        <button
          type="button"
          className="bg-gradient-to-r w-fit flex items-center gap-1  from-blue-400 to-blue-600 text-white sm:text-[0.7rem]  xsm:text-[0.5rem] xsm:px-1 sm:px-4 xsm:py-1  md:px-6 sm:py-2 rounded-full"
          onClick={handleAddCity}
        >
          <span>Add City</span>
          <IoMdAdd />
        </button>
      </div>

      <div className="mb-3 flex items-center bg-gray-100 rounded p-2">
        <FaMobileAlt className="text-xl mr-2" />
        <input
          type="Number"
          placeholder="Alternate mobile"
          className="bg-transparent focus:outline-none w-full"
          value={formData.alternateMobile}
          onChange={(e) => handleInputChange("alternateMobile", e.target.value)}
        />
      </div>
      <button
        type="submit"
        className={`w-full bg-blue-600 text-white rounded p-2 hover:bg-blue-700
          ${isFormValid ? "opacity-60 cursor-not-allowed" : "opacity-100 cursor-pointer"}
          `}
        disabled={isFormValid}
      >
        Search Trip
      </button>
      {
        alerModel !== null && (
          <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white rounded-lg shadow-lg p-6 w-full xsm:w-[90vw] md:max-w-md text-center">
              <h2 className=" text-[14px] md:text-xl font-bold text-gray-800 mb-4">Alert</h2>
              <p className="text-gray-600 text-[11px] md:text-[14px] mb-6">{alerModel}</p>
              <button
                className="bg-blue-500 text-[11px] md:text-[14px] hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded"
                onClick={onClose}
              >
                OK
              </button>
            </div>
          </div>
        )
      }
    </form>
  );

};

export default FindTripForm;
