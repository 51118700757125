import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import Navbar from "../Components/common/Navbar";
import SearchBar from "../Components/Core/TourAndTravels/Searchbar";
import axios from "axios";
import { BASE_API } from "../Services/apis";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import FillBookingDetail from "../Components/Core/TourAndTravels/FillDetail";
import { useNavigate } from "react-router-dom";

import SEOHelmet from "../Components/common/SeoHelmet";

const TTPackagePayment = () => {
    const navigate = useNavigate()
    const { user, token } = useSelector((state) => state.auth);
    const Data = JSON.parse(localStorage.getItem("SelectedTourPackage")) || {};
    const [selectedPayment, setSelectedPayment] = useState("part");
    const [queryParameters, setQueryParameters] = useState({});
    const [activeSection, setActiveSection] = useState("bookingDetails");

    const [bookingDetails, setBookingDetails] = useState({
        startDate: "",
        pickupTime: "",
        endDate: "",
        dropTime: "",
        adults: 0,
        children: 0,
        specialRequests: "",
        alternateMobile: user?.mobile || "",
        code: "91",
    });

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const pickupDate = new Date(params.get("pickupDate"));
        const duration = params.get("duration") + 1;
        const endDate = new Date(pickupDate);
        endDate.setDate(endDate.getDate() + duration);
        const formattedEndDate = endDate.toISOString().split("T")[0];

        const data = {
            tourPackageId: Data._id,
            startDate: params.get("pickupDate"),
            pickupTime: params.get("pickupTime"),
            endDate: formattedEndDate,
            dropTime: "12:40",
            alternateMobile: user?.mobile,
            adults: 2,
            children: 1,
            ...bookingDetails,
        };
        setQueryParameters(data);
    }, []);

    const handlePayment = async () => {
        const user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem('user')) : null;
        const token = localStorage.getItem("token") ? JSON.parse(localStorage.getItem('token')) : null;
        try {
            const payload = {
                tourPackageId: Data._id,
                ...bookingDetails
            }
            delete payload.code;
            if (!token && !user) {
               return navigate("/login")
            }

            const response = await axios.post(
                `${BASE_API}/booking/create/tour-and-package-booking`,
                payload,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (response.status === 200 || 201) {
                toast.success("Successfully booked!");
                navigate('/tour-package-booking-successful', { state: { booking: response?.data?.booking } });

            }
        } catch (error) {
            const errorMessage =
                error?.response?.data?.message ||
                error?.message ||
                "Something went wrong";
            toast.error(errorMessage);
            console.error("Error processing payment:", error);
        }
    };

    return (
        <section className="w-[100vw]">
            <SEOHelmet title="TourAndTravel Paymen | TSJ Car Rentals"/>

           
            <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
            >
                <Navbar />
            </motion.div>
            <section className="w-full bg-white py-10">
                <div className="w-[70vw] mx-auto">
                    <h1 className="text-2xl font-bold">{Data?.packageName}</h1>
                    <div className="flex items-center space-x-2 mt-2">
                        <span className="border border-gray-400 text-sm px-2 py-1 rounded">Customizable</span>
                        <span className="border border-gray-400 text-sm px-2 py-1 rounded">
                            {Data?.durationInDays}D/{Data?.durationInNight}N
                        </span>
                        <span>{Data?.destination}</span>
                    </div>

                    <div className="grid grid-cols-3 gap-4 mt-4">
                        {Data?.titleImg && (
                            <img src={Data.titleImg} alt="Main destination" className="w-full h-[200px] object-cover rounded-md" />
                        )}
                        {Array.isArray(Data?.imgs) &&
                            Data.imgs.slice(0, 3).map((img, index) => (
                                <img
                                    key={index}
                                    src={img}
                                    alt={`Additional view ${index + 1}`}
                                    className="w-full h-[200px] object-cover rounded-md"
                                />
                            ))}
                    </div>

                    <div className="flex mt-8 border-b">
                        <button
                            className={`px-4 py-2 font-semibold ${activeSection === "bookingDetails" ? "text-blue-600 border-b-2 border-blue-600" : ""}`}
                            onClick={() => setActiveSection("bookingDetails")}
                        >
                            FILL BOOKING DETAILS
                        </button>
                        <button
                            className={`px-4 py-2 font-semibold ${activeSection === "itinerary" ? "text-blue-600 border-b-2 border-blue-600" : ""}`}
                            onClick={() => setActiveSection("itinerary")}
                        >
                            ITINERARY
                        </button>
                    </div>
                </div>
            </section>

            <section className="flex w-[70vw] gap-4 mx-auto mt-10 h-auto mb-20">
                {/* Conditional Rendering */}
                {activeSection === "bookingDetails" ? (
                    <div className="w-2/3 bg-white p-6 rounded-md shadow-md">
                        <FillBookingDetail
                            bookingDetails={bookingDetails}
                            setBookingDetails={setBookingDetails}
                        />
                    </div>
                ) : (
                    <div className="w-2/3 bg-white p-6 rounded-md shadow-md">
                        <h2 className="text-lg font-semibold mb-4">Day Plan</h2>
                        <div>
                            {Data?.dayPlan?.map((day, index) => (
                                <div key={index} className="mb-4">
                                    <h3 className="text-md font-bold">{`Day ${index + 1} - ${day.date}`}</h3>
                                    <div className="flex items-start space-x-4 mt-2">
                                        <span className="font-semibold">{day.activityType}</span>
                                        <div>
                                            <h4 className="font-bold">{day.title}</h4>
                                            <p>{day.description}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                <div className="w-1/4 mx-auto text-[0.8rem] bg-white shadow-md rounded-md py-4 flex flex-col gap-2">
                    {/* Pay Now Button */}
                    <button
                        onClick={handlePayment}
                        style={{ background: "linear-gradient(90.95deg, #53B2FE 0%, #065AF3 100%)" }}
                        className="w-full bg-blue-500 text-white py-3 font-semibold text-lg mb-4"
                    >
                        PAY ₹{Data?.packagePrice} NOW
                    </button>

                    {/* Payment Options */}
                    <div className="space-y-4 p-4">
                        <label className="flex items-center">
                            <input
                                type="radio"
                                name="payment"
                                value="part"
                                checked={selectedPayment === "part"}
                                onChange={() => setSelectedPayment("part")}
                                className="mr-2"
                            />
                            <span className="flex justify-between w-full">
                                <div className="flex flex-col">
                                    <span className="text-black font-bold">Make part payment now ₹{Data?.packagePrice}</span>
                                </div>
                            </span>
                        </label>

                        <label className="flex items-center">
                            <input
                                type="radio"
                                name="payment"
                                value="full"
                                checked={selectedPayment === "full"}
                                onChange={() => setSelectedPayment("full")}
                                className="mr-2"
                            />
                            <span className="flex justify-between w-full">
                                <div className="flex flex-col">
                                    <span className="text-black font-bold">Pay After</span>
                                </div>
                            </span>
                        </label>
                    </div>

                    {/* Total Amounts */}
                    <div className="border-t border-gray-300 mt-4 w-[90%] mx-auto flex items-center justify-between">
                        <div className="flex text-lg font-semibold flex-col text-start">
                            <span className="font-bold">Total Amount</span>
                            <p className="text-sm text-gray-500">inc. of tolls and taxes</p>
                        </div>
                        <div>
                            <span className="flex items-center justify-end">
                                <span className="text-xl font-bold">₹{Data?.packagePrice}</span>
                            </span>
                            <span className="text-blue-500 font-semibold text-end">Fare Breakup</span>
                        </div>
                    </div>
                </div>
            </section>
        </section>
    );
};

export default TTPackagePayment;
