import React, { useState, useEffect } from "react";
import { useParams,useNavigate } from "react-router-dom";
import axios from "axios";
import { BASE_API } from "../Services/apis";
import { Typography, Box, CircularProgress } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import SEOHelmet from "../Components/common/SeoHelmet";

const TourBookingDetail = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const token = JSON.parse(localStorage.getItem("token"));
    const [bookingDetails, setBookingDetails] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchBookingDetails = async () => {
            try {
                const response = await axios.get(`${BASE_API}/tour-package-booking-details/${id}`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                if (response.status === 200) {
                    setBookingDetails(response.data?.bookings || {});
                } else {
                    throw new Error("Failed to fetch booking details");
                }
            } catch (err) {
                console.error("Error:", err);
                setError("Error fetching booking details");
            } finally {
                setLoading(false);
            }
        };

        fetchBookingDetails();
    }, [id, token]);

    if (loading) return <CircularProgress />;
    if (error) return <Typography color="error">{error}</Typography>;

    const {
        _id,
        name,
        nationality,
        mobile,
        alternateMobile,
        email,
        packageName,
        destination,
        durationInDays,
        durationInNight,
        transportation,
        activities = [],
        groupSize,
        adults,
        children,
        titleImg,
        packagePrice,
        bookingDate,
        paymentStatus,
        bookingStatus,
        specialRequests,
        cancellationPolicyAcknowledged,
        tourPackageId = {},
        selectedDates = {},
    } = bookingDetails;

    return (
        <div className="bg-gray-50 px-4 md:px-8 py-6 lg:py-12">
             <SEOHelmet title="User TourAndTravel Booking Details | TSJ Car Rentals"/>
            <div className="flex justify-start my-4">
                <ArrowBackIcon onClick={() => navigate(-1)} />
            </div>
            <div className="max-w-6xl mx-auto space-y-6">
                <div className="bg-[#313c67] h-[120px] md:h-[150px] rounded-md p-4 flex flex-col justify-center">
                    <h1 className="text-white text-xl md:text-2xl font-bold">Booking Details</h1>
                    <div className="flex flex-wrap justify-between text-sm text-gray-600 mt-2">
                        <p className="text-white">
                            Booking ID: <span className="text-white font-semibold">{_id}</span>
                        </p>
                        <p className="text-white">
                            Status: <span className="text-white font-semibold">{bookingStatus}</span>
                        </p>
                        <p className="text-white">
                            Payment Status: <span className="text-white font-semibold">{paymentStatus}</span>
                        </p>
                        <p className="text-white">
                            Total Price: <span className="text-white font-semibold">₹{packagePrice}</span>
                        </p>
                    </div>
                </div>

                <div className="bg-white shadow-md rounded-lg p-4">
                    <h2 className="text-lg font-bold mb-4">User Details</h2>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                        <p className="text-left">
                            <strong>Name:</strong> {name}
                        </p>
                        <p className="text-left">
                            <strong>Email:</strong> {email}
                        </p>
                        <p className="text-left">
                            <strong>Mobile:</strong> {mobile}
                        </p>
                        <p className="text-left">
                            <strong>Alternate Mobile:</strong> {alternateMobile}
                        </p>
                        <p className="text-left">
                            <strong>Nationality:</strong> {nationality}
                        </p>
                    </div>
                </div>

                <div className="bg-white shadow-md rounded-lg p-4">
                    <h2 className="text-lg font-bold mb-4">Package Details</h2>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                        <p className="text-left">
                            <strong>Package Name:</strong> {packageName}
                        </p>
                        <p className="text-left">
                            <strong>Destination:</strong> {destination}
                        </p>
                        <p className="text-left">
                            <strong>Duration:</strong> {durationInDays} Days, {durationInNight} Nights
                        </p>
                        <p className="text-left">
                            <strong>Transportation:</strong> {transportation}
                        </p>
                        <p className="text-left">
                            <strong>Group Size:</strong> {groupSize}
                        </p>
                        <p className="text-left">
                            <strong>Adults:</strong> {adults}
                        </p>
                        <p className="text-left">
                            <strong>Children:</strong> {children}
                        </p>
                        <p className="text-left">
                            <strong>Special Requests:</strong> {specialRequests || "None"}
                        </p>
                        <p className="text-left">
                            <strong>Booking Date:</strong> {new Date(bookingDate).toDateString()}
                        </p>
                    </div>
                </div>

                <div className="bg-white shadow-md rounded-lg p-4">
                    <h2 className="text-lg font-bold mb-4">Activities Included</h2>
                    <ul className="list-disc ml-5 space-y-1">
                        {activities.map((activity, index) => (
                            <li className="text-left" key={index}>{activity}</li>
                        ))}
                    </ul>
                </div>

                <div className="bg-white shadow-md rounded-lg p-4">
                    <h2 className="text-lg font-bold mb-4">Selected Dates</h2>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                        <p className="text-left">
                            <strong>Start Date:</strong> {new Date(selectedDates.startDate).toDateString()}
                        </p>
                        <p className="text-left">
                            <strong>Pickup Time:</strong> {selectedDates.pickupTime}
                        </p>
                        <p className="text-left">
                            <strong>End Date:</strong> {new Date(selectedDates.endDate).toDateString()}
                        </p>
                        <p className="text-left">
                            <strong>Drop Time:</strong> {selectedDates.dropTime}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TourBookingDetail;
