import React from 'react';
import { Container, Grid, Paper, Box } from '@mui/material';
import { Outlet } from 'react-router-dom';

const Dashboard = () => {
    return (
        <Paper sx={{ boxShadow: 'none', margin: "2px" ,height:"100vh"}}>
            <Grid container spacing={3} >
                <Grid item xs={12} style={{ marginTop: 0, paddingTop: 0 }}>
                    <Paper style={{ padding: 0, margin: 3, boxShadow: "none", overflowY: "scroll", paddingTop: 2, msOverflowStyle: "none", scrollbarWidth: "none" }}>
                        <Outlet />
                    </Paper>
                </Grid>
            </Grid>
        </Paper>
    );
};
export default Dashboard;   
