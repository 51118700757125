import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { BASE_API } from "../Services/apis";
import { Typography, Button, CircularProgress } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import SEOHelmet from "../Components/common/SeoHelmet";

const RentalBookingDetail = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const token = JSON.parse(localStorage.getItem("token"));
    const [bookingDetails, setBookingDetails] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);



    useEffect(() => {
        const fetchBookingDetails = async () => {
            try {
                const response = await axios.get(`${BASE_API}/get-booking-details/${id}`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                if (response.status === 200) {
                    setBookingDetails(response.data?.data || {});
                } else {
                    throw new Error("Failed to fetch booking details");
                }
            } catch (err) {
                setError("Error fetching booking details");
            } finally {
                setLoading(false);
            }
        };

        fetchBookingDetails();
    }, [id, token]);

    if (loading) return <CircularProgress />;
    if (error) return <Typography color="error">{error}</Typography>;

    const {
        _id,
        userId = {},
        vehicleId = {},
        pickupDate,
        dropDate,
        pickupTime,
        dropTime,
        pickupLocation,
        dropLocation,
        totalAmount,
        bookingStatus,
        paymentStatus,
        cancellationPolicy,
        contactNumber,
    } = bookingDetails;

    return (
        <div className="bg-gray-50 px-4 md:px-8 py-6 lg:py-12">
            <SEOHelmet title="User Rental Booking Details | TSJ Car Rentals"/>
           
            <div className="flex justify-start my-4">
                <ArrowBackIcon onClick={() => navigate(-1)} />
            </div>

            <div className="max-w-6xl mx-auto">

                <div className="bg-[#313c67] h-[120px] md:h-[150px] rounded-md mb-6 p-4">
                    <h1 className="text-black text-xl md:text-2xl font-bold">Booking Details</h1>
                    <div className="flex flex-wrap justify-between text-sm text-gray-600 mt-2">
                        <p className="text-white">Booking ID: <span className="text-white font-semibold">{_id}</span></p>
                        <p className="text-white">Status: <span className="text-white font-semibold">{bookingStatus}</span></p>
                        <p className="text-white">Payment Status: <span className="text-white font-semibold">{paymentStatus}</span></p>
                        <p className="text-white">Total Amount: <span className="text-white font-semibold">₹{totalAmount}</span></p>
                        {/* <img
                            src={vehicleId.img}
                            alt={vehicleId.vehicleModel}
                            className="w-full mt-4 rounded-md w-[400px]"
                        /> */}
                    </div>
                </div>

                <div className="bg-white shadow-md rounded-lg p-4 mb-4">
                    <h2 className="text-lg font-bold mb-4">User Details</h2>
                    <p className="text-left">Name: {userId.name}</p>
                    <p className="text-left">Email: {userId.email}</p>
                    <p className="text-left">Mobile: {userId.mobile}</p>
                </div>

                <div className="bg-white shadow-md rounded-lg p-4 mb-4">
                    <h2 className="text-lg font-bold mb-4">Trip Details</h2>
                    <p className="text-left"><strong>Pickup Location:</strong> {pickupLocation} ({pickupTime}, {new Date(pickupDate).toDateString()})</p>
                    <p className="text-left"><strong>Drop Location:</strong> {dropLocation} ({dropTime}, {new Date(dropDate).toDateString()})</p>
                    <p className="text-left"><strong>Contact Number:</strong> {contactNumber}</p>
                </div>

                <div className="bg-white shadow-md rounded-lg p-4">
                    <h2 className="text-lg font-bold mb-4">Vehicle Details</h2>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                        <p className="text-left"><strong>Type:</strong> {vehicleId.vehicleType}</p>
                        <p className="text-left"><strong>Model:</strong> {vehicleId.vehicleModel}</p>
                        <p className="text-left"><strong>Number:</strong> {vehicleId.vehicleNumber}</p>
                        <p className="text-left"><strong>Fuel Type:</strong> {vehicleId.fuelType}</p>
                        <p className="text-left"><strong>Transmission:</strong> {vehicleId.transmission}</p>
                        <p className="text-left"><strong>Cancellation Policy:</strong> {cancellationPolicy}</p>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default RentalBookingDetail;
